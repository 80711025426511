import HomeMain from "components/Home/HomeMain";
import Layout from "components/Layout/Layout";
import React from "react";

const Home = () => {
  return (
    <Layout title={"Home"}>
      <HomeMain />
    </Layout>
  );
};

export default Home;
