import React from "react";
const Button2 = ({ disabled, className, children, ...props }) => {
  return (
    <div>
      <button
        disabled={disabled}
        {...props}
        className={` ${className}    tracking-wider bg-white   font-semibold hover:bg-gray-200    px-10 py-3  w-full     text-sm  flex justify-center   rounded-full`}
      >
          {children}
      </button>
    </div>
  );
};

export default Button2;
