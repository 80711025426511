import order from "assets/images/no-order.png";
import Image from "components/Layout/Fields/Image";

import React, { Fragment } from "react";
import Currency from "react-currency-formatter";
import { FiHeart } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Card = ({ item }) => {
const navigation = useNavigate()

  return (
    <Fragment>
      <div onClick={()=>navigation('/order/detail')} className="  cursor-pointer flex flex-col gap-2   pb-4 relative  ">
        <div className="  h-[200px] sm:h-[350px] overflow-hidden rounded">
          <Image className={""} src={item.image} alt="" />
        </div>
        <div>
          <div className="  flex px-2   gap-1 flex-col">
            <div className=" flex flex-col gap-1">
              <div className=" flex   flex-col gap-1 md:flex-row justify-between">
                <h1 className=" text-white  font-medium text-sm underline">
                  106401806
                </h1>
                <h1 className=" text-xs  text-white  ">
                  Date ordered : 16/6/2024
                </h1>
              </div>
              <div className=" flex   flex-col gap-1 md:flex-row justify-between">
                <h1 className=" text-white  font-medium text-xs">
                  {item.title}
                </h1>{" "}
                <h1 className="  text-primary-100 font-medium text-xs">
                  <Currency quantity={item.price} currency="inr" />
                </h1>
              </div>
            </div>

            <h1 className="  text-secondary-300 text-xs">Order status : Delivered</h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Orders = () => {
  const data = [
    {
      id: 1,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/CollegiateShadowWashedTankGSAsphaltGrey-STONEACIDWASHA5A4Y-GCSD_9607392a-4137-4262-9069-ae3e2a258f78_640x.jpg?v=1718012567",
      title: "Arrival 5 Shorts ",
      title2: "Light Support",
      price: 8295,
    },
    {
      id: 2,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_1664x.jpg?v=1716981418",
      title: "MESH TANK ",
      title2: "Regular Fit",
      price: 5295,
    },
    {
      id: 3,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/OversizedPerformanceT-ShirtGSBlackA6A4W-BB2J-0263_640x.jpg?v=1716981420",
      title: "Blur Seamless Cami ",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 4,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSTitaniumBlueA6A2M-UCMQ-0582_7c5f3c19-9d89-4baa-9eb9-3f1748bad9fb_640x.jpg?v=1718102582",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 5,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/ARRIVAL5SHORTSBLACKA2A1M-BBBB_828x.jpg?v=1680785741",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 6,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/Sport5ShortBlack-A2A8K-BBBB-2274.241_640x.jpg?v=1677057795",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 7,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F743CBJvd4jWRsRTmXQ3GWt%2F371bdaf5397026a7239ec8ce759aab41%2FMens_Shorts_Shop_By_Length_7_5x4.jpg&w=1664&q=85",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 8,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2FLQs2kZbgrSrr77TCv6cAV%2F68e425eb60861bec72e4f9664047e06d%2FMens_Shorts_Shop_By_Collection_Apex.jpg&w=640&q=90",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
  ];

  return (
    <div className=" flex flex-col gap-10">
      <div>
        <h1 className="  tracking-wide  text-xl  font-semibold text-white uppercase">
          YOUR ORDERS
        </h1>
      </div>
      {/* <div className=" flex justify-center py-28">
        <div className=" flex flex-col  items-center">
          <div className="   w-40">
            <Image src={order} alt="" />
          </div>

          <div className=" text-center">
            <h1 className=" tracking-wide  text-base  font-semibold text-white uppercase">
              NO ORDERS
            </h1>
            <p className="      font-extralight  text-sm text-secondary-300">
              You’ve made no orders
            </p>
          </div>
        </div>
      </div> */}

      <div className=" grid grid-cols-2 2xl:grid-cols-3 gap-4">
        {data.map((item, index) => (
          <Card key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Orders;
