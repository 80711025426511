import React, { useState } from "react";
import ProductsCard from "./ProductCard";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Button4 from "components/Layout/Fields/Button4";
import InputText from "components/Layout/Fields/InputText";
import { RiLoopRightFill } from "react-icons/ri";

const Summery = () => {
  const data2 = [
    {
      id: 1,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_1664x.jpg?v=1716981418",
      name: "Mesh Tank",
      color: "black",
      size: "Size",
    },
    {
      id: 1,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MinimalSportsBraWhiteB3A2J-WBBM-0663_3840x.jpg?v=1714127922",
      name: "MINIMAL SPORTS BRA",
      color: "White",
      size: "",
    },
  ];
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(false);

  const [coupon, setCoupon] = useState("");

  const Icon = show ? IoIosArrowUp : IoIosArrowDown;
  const Icon2 = show2 ? IoIosArrowUp : IoIosArrowDown;

  const openShow = () => {
    setShow2(!show2);
  };
  const closeShow = () => {
    setShow2(false);
  };

  return (
    <div className=" flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div
          onClick={() => setShow(!show)}
          className=" bg-secondary-300 p-4 flex justify-between items-center cursor-pointer"
        >
          <h1 className=" uppercase text-secondary-100  text-base">
            Order details (2)
          </h1>

          <Icon className=" text-secondary-100" size={20} />
        </div>
        {show && (
          <div className=" flex flex-col gap-2">
            {data2.map((item, index) => (
              <ProductsCard item={item} key={index} />
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <div
          onClick={openShow}
          className=" bg-secondary-300 p-4 flex justify-between items-center cursor-pointer"
        >
          <h1 className=" uppercase text-secondary-100  text-base">
            APPLY A COUPON CODE
          </h1>

          <Icon2 className=" text-secondary-100" size={20} />
        </div>
        {show2 && (
          <div className=" flex gap-4 items-center">
            <InputText
              required={true}
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              type={"text"}
              className=""
              placeholder={"Enter a coupon code"}
              // error={errors.name}
            />
            <Button4 className={"  uppercase"}>Apply</Button4>
          </div>
        )}
      </div>

      <div>
      <div className=" flex items-center  justify-start gap-2 ">
        <RiLoopRightFill size={18} className="  text-secondary-300" />
        <h1 className=" text-secondary-300 text-xs  md:text-sm font-medium uppercase tracking-wider">
          Free returns on all qualifying orders.
        </h1>
      </div>
        <div className=" border-y py-4 pb-7 my-3 border-secondary-200 flex flex-col gap-2">
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SUBTOTAL
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              ₹ 8,700.00
            </h1>
          </div>
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              COUPON DISCOUNT 10%{" "}
              <span className="  text-primary-100">(COU10)</span>
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              - ₹ 100.00
            </h1>
          </div>
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SHIPPING COSTS
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              ₹ 100.00
            </h1>
          </div>
        </div>
        <div className=" flex justify-between items-center">
          <h1 className="   text-base font-medium text-secondary-300 tracking-wider">
            GRAND TOTAL{" "}
            <span className=" text-xs  text-primary-100">
              {" "}
              PRICES INCLUDE GST
            </span>
          </h1>
          <h1 className=" text-base font-medium   text-white tracking-wider">
            ₹ 100.00
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Summery;
