import Layout from "components/Layout/Layout";
import ViewMain from "components/ProductView/ViewMain";
import React from "react";
import { useLocation } from "react-router-dom";

const ProductView = () => {
  const location = useLocation();
  const propsReceived = location.state;

  return (
    <Layout title={"Products"}>
      <ViewMain />
    </Layout>
  );
};

export default ProductView;
