import Button2 from "components/Layout/Fields/Button2";
import Button3 from "components/Layout/Fields/Button3";
import Image from "components/Layout/Fields/Image";
import InputTextArea from "components/Layout/Fields/InputTextArea";
import Select from "components/Layout/Fields/Select";
import ModalSection from "components/Layout/ModalSection";
import React, { Fragment, useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";
import { MdLoop } from "react-icons/md";
const image =
  "https://cdn.shopify.com/s/files/1/1367/5207/files/CollegiateShadowWashedTankGSAsphaltGrey-STONEACIDWASHA5A4Y-GCSD_9607392a-4137-4262-9069-ae3e2a258f78_640x.jpg?v=1718012567";

const Detail = () => {
  return (
    <div>
      <div className=" flex flex-col gap-1">
        <h1 className=" text-base   font-light   text-white">Order Number :</h1>
        <h1 className="text-sm   font-light      text-secondary-300 underline">
          106401806
        </h1>

        <h1 className=" text-sm  font-extralight   text-white ">
          Date ordered :{" "}
          <span className="text-sm   font-light   text-white ">16/6/2024</span>{" "}
        </h1>
        <h1 className=" text-sm  font-extralight   text-white ">
          Order status :{" "}
          <span className="text-sm   font-light   text-white ">DELIVERED</span>{" "}
        </h1>
      </div>
    </div>
  );
};

const Tracking = ({ openModal }) => {
  return (
    <div className="  bg-secondary-300 p-5 flex flex-col gap-5">
      <div>
        <h1 className=" text-base  font-medium tracking-wide">
          SHIPPING 1 OF 1{" "}
        </h1>
        <h1 className=" text-sm tracking-wide ">
          Tracking Number : <br />
          <span className=" underline  font-medium"> 2667251932531</span>
        </h1>
      </div>
      <div className=" border border-secondary-200 p-4 flex  flex-col lg:flex-row justify-between gap-4">
        <p className=" text-sm  font-light">
          Need to return an item ? No problem. You can initiate return of any of
          the products <br className=" hidden md:flex" /> until{" "}
          <span className=" font-medium">30/6/2024</span>
        </p>
        <button
          onClick={openModal}
          className=" border-2 border-secondary-200 p-3 text-xs  font-medium "
        >
          Initiate Return / Exchange
        </button>
      </div>
      <div className=" flex  bg-white p-3  gap-4">
        <div className="   w-24">
          <Image src={image} />
        </div>
        <div>
          <h1 className=" font-medium tracking-wide">Arrival 5 Shorts</h1>
          <div>
            <div>
              <h1 className=" font-light text-sm">
                Price : <span>₹ 8,295.00</span>
              </h1>
              <h1 className=" font-light text-sm">
                Size : <span>XL</span>
              </h1>
              <h1 className=" font-light text-sm">
                Color : <span>Black</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Address = () => {
  return (
    <div className="">
      <div className=" border  flex flex-col gap-4  border-secondary-200 p-5">
        <h1 className=" text-base  font-medium tracking-wide text-white">
          ADDRESSES
        </h1>
        <div className=" grid gap-6 lg:gap-0 lg:grid-cols-2">
          <div className="  flex flex-col gap-2">
            <h1 className=" text-sm font-medium tracking-wide  text-white">
              Shipping Address:
            </h1>
            <address>
              <p className="text-secondary-300   text-sm  font-light ">
                Ashish Khanna <br />
                Mohali
                <br />
                140000 Mohali PB <br />
                India <br />
              </p>
            </address>
          </div>
          <div className="  flex flex-col gap-2">
            <h1 className=" text-sm font-medium tracking-wide  text-white">
              Contact Info:
            </h1>
            <div>
              <h1 className=" text-secondary-300   text-sm  font-light">
                aashishkhanna@gmail.com
              </h1>
              <h1 className="text-secondary-300   text-sm  font-light">
                +9100000000
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Payment = () => {
  return (
    <div className="">
      <div className=" border  flex flex-col gap-4  border-secondary-200 p-5">
        <h1 className=" text-base  font-medium tracking-wide text-white">
          PAYMENT
        </h1>
        <div className=" ">
          <div className="  flex flex-col gap-3">
            <h1 className=" text-sm   font-extralight tracking-wide  text-white">
              Cash On Delivery
            </h1>
            <div className=" flex flex-col gap-2 border-b  border-secondary-200 pb-5">
              <h1 className=" text-sm font-medium tracking-wide  text-white">
                Order Summary
              </h1>
              <div className="flex  flex-col gap-1">
                <div className=" flex justify-between">
                  <h1 className=" text-sm  font-normal tracking-wide  text-secondary-300">
                    Subtotal
                  </h1>
                  <h1 className="text-sm font-extralight tracking-wide  text-white">
                    ₹8,295.00
                  </h1>
                </div>
                <div className=" flex justify-between">
                  <h1 className=" text-sm  font-normal tracking-wide  text-secondary-300">
                    Shipping costs
                  </h1>
                  <h1 className="text-sm  font-extralight tracking-wide  text-white">
                    ₹200
                  </h1>
                </div>
              </div>
            </div>
            <div className=" flex justify-between">
              <h1 className=" text-sm  font-semibold tracking-wide  text-secondary-300">
                Total
              </h1>
              <h1 className="text-sm   font-semibold tracking-wide  text-white">
                ₹ 8500
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DetailMain = () => {
  const [status, setStatus] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectStatus = (data) => {
    setStatus(data);
  };
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [reason, setReason] = useState();

  const handleChange = (event) => {
    setReason(event.target.value);
  };

  const [size, setSize] = useState(null);

  const handleSizeChange = (event) => {
    setSize(event.target.value);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setStatus(null);
    setSize(null);
    setReason(null);
  };

  const States = [
    {
      id: 1,
      value: "Do not like the size / fit ",
      label: "Do not like the size / fit ",
    },
    {
      id: 2,
      value: "Received a wrong / defective product",
      label: "Received a wrong / defective product",
    },
    { id: 3, value: "Product quality issue", label: "Product quality issue" },
    {
      id: 4,
      value: "Do not want the product anymore",
      label: "Do not want the product anymore",
    },
    { id: 5, value: "Other Reason", label: "Other Reason" },
  ];

  const sizeOptions = [
    {
      id: 1,
      value: "S",
      label: "S",
    },
    {
      id: 2,
      value: "M",
      label: "M",
    },
    { id: 3, value: "L", label: "L" },
    {
      id: 4,
      value: "XL",
      label: "XL",
    },
  ];

  return (
    <Fragment>
      <div className="bg-secondary-100  py-10  ">
        <div className=" container   px-5   xl:px-44 flex flex-col gap-8 pb-20 ">
          <h1 className="font-semibold  text-lg md:text-2xl uppercase text-white tracking-wide">
            Order details
          </h1>
          <Detail />
          <Tracking openModal={openModal} />
          <Address />
          <Payment />
        </div>
      </div>

      <ModalSection isOpen={isModalOpen} onClose={closeModal} className="">
        <Fragment>
          <div className="  flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-sm  tracking-wider font-medium">
                Return Or Exchange
              </h1>
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>
            <div>
              <div className=" flex p-3  gap-4">
                <div className="    w-20">
                  <Image src={image} />
                </div>
                <div>
                  <h1 className=" font-medium  text-sm tracking-wide">
                    Arrival 5 Shorts
                  </h1>
                  <div>
                    <div>
                      <h1 className=" font-light text-xs">
                        Price : <span>₹ 8,295.00</span>
                      </h1>
                      <h1 className=" font-light text-xs">
                        Size : <span>XL</span>
                      </h1>
                      <h1 className=" font-light text-xs">
                        Color : <span>Black</span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              {status !== null && (
                <div className=" w-full  flex flex-col gap-5   ">
                  <Select
                    required={true}
                    label={"Reason"}
                    id="qty"
                    onChange={handleChange}
                    value={reason}
                    options={States}
                    color={"text-secondary-100"}
                    className="  text-secondary-100"
                  />
                  {status === "exchange" && (
                    <Select
                      required={true}
                      label={"Size"}
                      id="qty"
                      onChange={handleSizeChange}
                      value={size}
                      options={sizeOptions}
                      color={"text-secondary-100"}
                      className="  text-secondary-100"
                    />
                  )}

                  <InputTextArea
                    placeholder={"Max. 200 characters"}
                    label={"Comment (optional)"}
                    color={"text-secondary-100"}
                    className="  text-secondary-100"
                  />
                </div>
              )}
            </div>
            {status === null ? (
              <div className=" grid   md:grid-cols-2  gap-3 md:gap-5">
                <Button2
                  onClick={() => selectStatus("return")}
                  disabled={false}
                  className={
                    "whitespace-pre md:px-5  py-2lg:px-10  text-sm uppercase border  text-secondary-100 flex items-center gap-2 hover:border-secondary-200  border-secondary-100 hover:border-border-100"
                  }
                >
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.9987 4.16732V0.833984L2.83203 5.00065L6.9987 9.16732V5.83398C9.75703 5.83398 11.9987 8.07565 11.9987 10.834C11.9987 13.5923 9.75703 15.834 6.9987 15.834C4.24036 15.834 1.9987 13.5923 1.9987 10.834H0.332031C0.332031 14.5173 3.31536 17.5007 6.9987 17.5007C10.682 17.5007 13.6654 14.5173 13.6654 10.834C13.6654 7.15065 10.682 4.16732 6.9987 4.16732Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Return
                </Button2>

                <Button3
                  onClick={() => selectStatus("exchange")}
                  disabled={false}
                  className={
                    "whitespace-pre md:px-5  py-2lg:px-10  text-sm  bg-secondary-100   hover:bg-secondary-300 uppercase     text-white flex items-center gap-2  "
                  }
                >
                  <MdLoop size={20} />
                  Exchange
                </Button3>
              </div>
            ) : (
              <Button3
                onClick={closeModal}
                disabled={false}
                className={
                  "whitespace-pre md:px-5  py-2lg:px-10  text-sm  bg-secondary-100   hover:bg-secondary-300 uppercase     text-white flex items-center gap-2  hover:border-border-100"
                }
              >
                Next
              </Button3>
            )}
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default DetailMain;
