import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Address from "./Address";
import Summery from "./Summery";
import toast from "react-hot-toast";
import Logo from "assets/images/darklogo.png";

const Tabs = () => {
  return (
    <div>
      <div className=" flex gap-4">
        <NavLink to={"/cart"}>
          <h1 className=" text-sm tracking-wider cursor-pointer uppercase  text-secondary-300 border-b   ">
            cart
          </h1>
        </NavLink>
        <NavLink to={"/checkout"}>
          <h1 className=" text-sm tracking-wider  cursor-text uppercase  text-white border-b   ">
            payment
          </h1>
        </NavLink>
      </div>
    </div>
  );
};

const CheckoutMain = () => {
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
      }

      const options = {
        key: "rzp_test_2AIx1UksS9Y7Rz",
        // key: "rzp_test_w71iPMdJlOE1U2",
        image: Logo,
        currency: "INR",
        amount: 5000 * 100,
        name: "One Percent Club Aw",
        description: "Thanks for purchasing",
        theme: {
          color: "rgba(25,25,25)",
        },
        handler: async (response) => {},

        prefill: {
          name: "Ashish",
          email: "aashishkhanna166@gmail.com",
          contact: "7018548021",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);

  const createOrder = () => {
    const apiKey = "rzp_test_2AIx1UksS9Y7Rz";
    const apiUrl = "https://api.razorpay.com/v1/orders";

    const requestData = {
      amount: 50000,
      currency: "INR",
      receipt: "receipt#1",
      notes: {
        key1: "value3",
        key2: "value2",
      },
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Basic ${btoa(apiKey)}`, // Encoding API key in base64
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Order created:", data);
        // Handle response data as needed
      })
      .catch((error) => {
        console.error("Error creating order:", error);
        // Handle errors
      });
  };

  return (
    <div className="bg-secondary-100  flex  flex-col gap-10  ">
      <div className=" py-5  pb-20  ">
        <div className=" container   px-5    2xl:px-40 ">
          <div className=" flex flex-col  gap-5">
            <div>
              <h1 className=" text-white text-xl md:text-2xl  font-semibold tracking-wider">
                CHECKOUT
              </h1>
            </div>

            <div>
              <Tabs />
            </div>

            <div className="  py-5   gap-10 lg:gap-0   flex-col-reverse lg:flex-row  flex justify-between">
              <div className=" lg:w-[58%]">
                <Address displayRazorpay={displayRazorpay} />
              </div>
              <div className=" lg:w-[40%]">
                <Summery />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutMain;
