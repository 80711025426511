
import React, { Fragment, useState } from "react";

import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

const Address = () => {
 
  return (
    <Fragment>
      <div className="  flex flex-col gap-5">
        <div>
          <h1 className="  tracking-wide  text-xl  font-semibold text-white uppercase">
            YOUR ADDRESSES
          </h1>
        </div>
        <div className=" border  border-secondary-200 p-6">
          <div className=" flex justify-between">
            <div>
              <address>
                <p className=" text-secondary-300 text-sm  font-light">
                  Ashish Kumar <br />
                  Divine <br />
                  140307 Mohali PB <br />
                  India
                </p>
              </address>
            </div>
            <div className=" flex flex-col gap-2  items-end ">
              <button className=" text-white   flex items-center  gap-2">
                <MdEdit size={16} />
                <span className=" underline   font-medium text-sm "> Edit</span>
              </button>
              <button className=" text-white   flex items-center  gap-2">
                <RiDeleteBin6Line size={16} />
                <span className=" underline   font-medium text-sm ">
                  {" "}
                  Delete
                </span>
              </button>

              <div className="  flex gap-2 items-center">
                  <div
                    className={`
             
                  border-white p-1 
           
                
              
              border  rounded-full cursor-pointer `}
                  >
                    <div className={`  bg-white p-1.5 rounded-full 1`}></div>
                  </div>
                <p className="    text-secondary-300 text-sm font-light">
                  Make this my main
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    

    </Fragment>
  );
};

export default Address;
