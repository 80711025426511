import Image from "components/Layout/Fields/Image";
import React, { useEffect, useState } from "react";
import Logo from "assets/images/logo.png";
import InputText from "components/Layout/Fields/InputText";
import Button2 from "components/Layout/Fields/Button2";

const Poster = () => {
  const promotion = [
    {
      id: 1,
      title: "TRACK YOUR ORDERS",
      title2: "Keep track the status of your orders",
    },
    {
      id: 2,
      title: "SHOP YOUR WAY",
      title2:
        "Discover the latest launches and be the first to get notifications for new drops",
    },
    {
      id: 3,
      title: "SAVE WHAT YOU SEE",
      title2:
        "Save your most-loved activewear pieces to build your perfect outfit",
    },
    {
      id: 4,
      title: "FREE ONE PERCENT CLUB AW TRAIN ACCESS",
      title2:
        "Save your most-loved activewear pieces to build your perfect outfit",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === promotion?.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex?.length]);

  return (
    <div className="  relative h-[100vh]">
      <div className="  h-[100vh]">
        <Image src={"https://cdn.develop.gymshark.com/auth-img.jpg"} />
      </div>

      <div className=" absolute top-0 w-full  flex   items-center   p-16   h-full   ">
        <div className=" flex flex-col gap-4">
          {promotion.map((item, index) => (
            <div
              key={index}
              className={`
               ${
                 currentIndex === index ? "opacity-100" : "opacity-0 hidden"
               }  transition-all delay-75  `}
            >
              <div className=" flex flex-col gap-4">
                <div className=" flex flex-col gap-6">
                  <h1 className=" text-xs      text-secondary-300">
                    One account, across all Apps.
                  </h1>
                  <h1 className="   text-white  text-5xl font-semibold">
                    {item.title}
                  </h1>
                  <p className="  text-white">{item.title2}</p>
                </div>
              </div>
            </div>
          ))}
          <div className="  flex gap-2  ">
            {promotion.map((item, index) => (
              <div
                onClick={() => setCurrentIndex(index)}
                key={index}
                className={` rounded-full cursor-pointer    p-1   hover:bg-white  ${
                  currentIndex === index ? " bg-white" : "  bg-gray-500"
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const Login = ({ select, selectOption }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className=" h-[100vh] px-5  ">
      <div className=" flex w-full  h-full py-10 lg:py-0 justify-center lg:items-center">
        <div className=" w-[500px] flex flex-col gap-5">
          <div className=" flex justify-center ">
            <div className="  w-16 h-11 cursor-pointer  ">
              <img className=" w-full h-full " src={Logo} alt="" />
            </div>
          </div>

          <h1 className=" text-center font-semibold text-xl uppercase  tracking-wide  text-white">
            One Percent Club Aw
          </h1>
          <div className=" flex justify-center">
            <div className="bg-secondary-200 p-1 rounded-full">
              <button
                //   style={{ transition: 'all 0.25s ease-in-out'}}
                onClick={() => selectOption(1)}
                className={` ${
                  select === 1
                    ? "bg-white text-secondary-100"
                    : " text-white hover:text-secondary-100"
                } uppercase font-semibold text-sm py-2 px-10 rounded-full`}
              >
                Log in
              </button>
              <button
                //    style={{ transition: 'all 0.25s ease-in-out'}}
                onClick={() => selectOption(2)}
                className={` ${
                  select === 2
                    ? "bg-white text-secondary-100"
                    : "text-white hover:text-secondary-100"
                } uppercase font-semibold  text-sm py-2 px-10 rounded-full`}
              >
                Sign up
              </button>
            </div>
          </div>

          <div
            className={`transition duration-300 ease-in-out ${
              select === 1 ? "opacity-100" : "hidden opacity-0"
            }`}
          >
            <form action="" className=" flex flex-col gap-5 ">
              <div className="   flex flex-col   gap-5">
                <div className="   ">
                  <InputText
                    label={"Email"}
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={"email"}
                    className=""
                    placeholder={"Email"}

                    // error={errors.name}
                  />
                </div>
                <div className="   ">
                  <InputText
                    label={"PASSWORD "}
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={"password"}
                    className=""
                    placeholder={"Password"}
                    maxLength={20}
                    showPassword={showPassword}
                    togglePassword={() => setShowPassword(!showPassword)}
                    // error={errors.name}
                  />
                </div>
              </div>
              <div>
                <h1 className=" text-center   hover:text-secondary-300  font-medium text-white text-sm underline cursor-pointer">
                  Forgot Password?
                </h1>
              </div>

              <Button2 className={" uppercase font-semibold text-sm"}>
                Log In
              </Button2>
            </form>
          </div>

          <div
            className={`transition duration-300 ease-in-out ${
              select === 2 ? "opacity-100" : "hidden opacity-0"
            }`}
          >
            <form action="" className=" flex flex-col gap-5 ">
              <div className="   flex flex-col   gap-5">
                <div className="   ">
                  <InputText
                    label={"FIRST NAME"}
                    required={true}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"First Name"}

                    // error={errors.name}
                  />
                </div>
                <div className="   ">
                  <InputText
                    label={"LAST NAME"}
                    required={true}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"Last Name"}

                    // error={errors.name}
                  />
                </div>
                <div className="   ">
                  <InputText
                    label={"Email"}
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type={"email"}
                    className=""
                    placeholder={"Email"}

                    // error={errors.name}
                  />
                </div>
                <div className="   ">
                  <InputText
                    label={"PASSWORD "}
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={"password"}
                    className=""
                    placeholder={"Password"}
                    maxLength={20}
                    showPassword={showPassword}
                    togglePassword={() => setShowPassword(!showPassword)}
                    // error={errors.name}
                  />
                </div>
              </div>

              <div className="  flex  items-start gap-2">
                <input type="checkbox" name="" id="check_policy" className="  mt-2" />

                <label
                  htmlFor="check_policy"
                  className="    cursor-default  font-normal text-sm text-secondary-300"
                >
                  Tick here to receive emails about our products, apps, <br className=" hidden  md:flex" />{" "}
                  sales, exclusive content and more. View our{" "}
                  <span className=" text-white cursor-pointer">
                    Privacy Policy
                  </span>
                  .
                </label>
              </div>
              <Button2 className={" uppercase font-semibold text-sm"}>
                Create
              </Button2>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginMain = () => {
  const [select, setSelect] = useState(1);

  const selectOption = (option) => {
    setSelect(option);
  };

  return (
    <div className="  bg-secondary-100      h-[100vh]">
      <div className=" xl:grid grid-cols-2">
        <div className=" hidden xl:flex flex-col ">

        <Poster />
        </div>
        
        <Login select={select} selectOption={selectOption} />
      </div>
    </div>
  );
};

export default LoginMain;
