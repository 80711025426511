import CartMain from "components/Cart/CartMain";
import Layout from "components/Layout/Layout";
import React from "react";
const ProductView = () => {
  return (
    <Layout title={"Cart"}>
      <CartMain />
    </Layout>
  );
};

export default ProductView;
