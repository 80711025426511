import React, { Fragment } from "react";
import MainPoster from "../MainPoster";
import ProductsSection from "../ProductsSection";
import ProductPoster from "../ProductPoster";

const AccessoriesMain = () => {
  const img1 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F2TQfEZ70DeAGCgW2AmrjSA%2F9bbd8c9c65f5a95b089e4102f286be0e%2FProduct_Push_Ecom__Collection_Page_Desktop_Mens.jpg&w=1664&q=85";
  const img2 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F3lQCAFxb7haULVzCw234Bi%2F5726ea7355e0f097da6e5d2c2137c2ee%2FHeadless_Desktop_-_3.jpg&w=3840&q=85";
  const data = [
    {
      id: 1,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/1LWaterBottleBlack-I2A6T-BBBB20_828x.jpg?v=1673514742",
      title: "Arrival 5 Shorts ",
      title2: "Light Support",
      price: 8295,
    },
    {
      id: 2,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/SiliconeLiftingStrapsBlackI1A4S-BBBB180_828x.jpg?v=1678781479",
      title: "MESH TANK ",
      title2: "Regular Fit",
      price: 5295,
    },
    {
      id: 3,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/CrewSocks3PkWhite_I1A2F-WBBM1_001cc294-5ade-416d-9cf5-e8e4d7febd0f_3840x.jpg?v=1689686816",
      title: "Blur Seamless Cami ",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 4,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/CrewSocks5pk-WhiteI2A3S-WBBM_8e89427b-746b-453d-bb4d-94654134d8da_828x.jpg?v=1673514737",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 5,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/SportsBottle-RiverStoneGreyDriftGreyI2A6M-GBZ5.129_828x.jpg?v=1673514733",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 6,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/EverydayHoldallBlack-SMALL-I3A1V-BBBB35_828x.jpg?v=1717097801",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 7,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/SharkheadCapBlack-I1A6R-BBF71_828x.jpg?v=1668069597",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 8,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/WRAPLIFTINGGLOVESGAGV4216.A-Edit_ZH_ZH_b88df441-e45a-4eb1-9b67-dbae1f7f56eb_828x.jpg?v=1634728098",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
  ];
  const button1 = {
    title: "Shop Lifting",
    path: "",
  };
  const button2 = {
    title: "Find out more",
    path: "",
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10 pb-20 ">
        {/* <MainPoster
          className={`h-[200px] md:h-[500px]`}
          img={img1}
          title={"NEW ARRIVALS"}
          title2={
            "Brand new drops, brand new reasons to go gym. You’re welcome."
          }
        /> */}
        <div className=" py-5 ">
          <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
            <ProductsSection
              data={data}
              title={"ALL ACCESSORIES"}
              title2={""}
            />
          </div>
        </div>

        {/* <ProductPoster
          className={`h-[700px] md:h-[600px]`}
          img={img2}
          title={"GYMSHARK LIFTING"}
          title2={
            "The looks you’ll love to lift in. Whatever your look, whatever you lift."
          }
          button1={button1}
          button2={button2}
        />
         */}
      </div>
    </Fragment>
  );
};

export default AccessoriesMain;
