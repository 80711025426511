import React from "react";
const Button5 = ({ disabled, className, children, ...props }) => {
  return (
    <div>
      <button
        disabled={disabled}
        {...props}
        className={` ${className}    font-semibold  tracking-wider px-10 py-3  w-full    text-sm  flex justify-center   rounded-full`}
      >
        {children}
    
      </button>
    </div>
  );
};

export default Button5;
