import AddressMain from "components/Address/AddressMain";
import Layout from "components/Layout/Layout";
import React from "react";

const Address = () => {
  return (
    <Layout title={"Addresses"}>
      <AddressMain />
    </Layout>
  );
};

export default Address;
