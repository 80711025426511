import Image from "components/Layout/Fields/Image";
import React, { Fragment } from "react";
import Empty from "assets/images/no-wishlist.png";
import Currency from "react-currency-formatter";
import { FiHeart } from "react-icons/fi";
import Button2 from "components/Layout/Fields/Button2";
import Button3 from "components/Layout/Fields/Button3";
const EmptyWishlist = () => {
  return (
    <div className=" py-20">
      <div className=" flex flex-col justify-center items-center    gap-5 ">
        <div className=" w-44">
          <Image src={Empty} />
        </div>
        <div className="  flex flex-col  text-center gap-1">
          <h1 className="text-lg text-white  font-semibold tracking-wide">
            YOUR WISHLIST IS EMPTY
          </h1>
          <p className=" text-secondary-300 text-xs">
            Any items that you save while browsing, <br /> will be added here,
            to your wishlist.
          </p>
        </div>

        <div className=" flex flex-col gap-2  w-64">
          <Button2 className={" uppercase  font-medium"}>Shop Mens</Button2>
          <Button3 disabled={false} className={""}>
            Shop Women
          </Button3>
        </div>
      </div>
    </div>
  );
};

const Cards = ({item}) => {
  return (

  <Fragment>
  <div className="  cursor-pointer flex flex-col gap-2   pb-4 relative  ">
    <div className="  h-[200px] sm:h-[450px] overflow-hidden rounded">
      <Image className={""} src={item.image} alt="" />
    </div>
    <div>
      <div className="  flex px-2   gap-1 flex-col">
        <div className=" flex flex-col gap-1">
          <h1 className=" text-white  font-medium text-sm">{item.title}</h1>
          <h1 className=" text-xs   text-secondary-300  font-light">
            {item.title2}
          </h1>
        </div>
        <h1 className="  text-primary-100 font-medium text-xs">
          <Currency quantity={item.price} currency="inr" />
        </h1>
      </div>
    </div>

    <div className=" absolute  right-2 top-2">
      <button
        className={`
        flex     rounded-full  overflow-hidden
        p-2    bg-backgound-300 text-secondary-200  `}
      >
        <FiHeart size={16} className="  fill-secondary-100 " />
      </button>
    </div>
  </div>
</Fragment>
  )


};

const WishlistMain = () => {
  const data = [
    {
      id: 1,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/CollegiateShadowWashedTankGSAsphaltGrey-STONEACIDWASHA5A4Y-GCSD_9607392a-4137-4262-9069-ae3e2a258f78_640x.jpg?v=1718012567",
      title: "Arrival 5 Shorts ",
      title2: "Light Support",
      price: 8295,
    },
    {
      id: 2,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_1664x.jpg?v=1716981418",
      title: "MESH TANK ",
      title2: "Regular Fit",
      price: 5295,
    },

   
  ];
  return (
    <div className="bg-secondary-100  py-10  ">
      <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
        <div>
          <h1 className=" text-lg text-white  font-semibold tracking-wide">
            YOUR WISHLIST{" "}
            <span className=" text-sm text-secondary-300">0 Products</span>
          </h1>

          {/* <div>
            <EmptyWishlist />
          </div> */}

<div className=" grid grid-cols-2  py-10 lg:grid-cols-3 xl:grid-cols-4 gap-3">

          {data?.map((item, index) => (
              <Cards item={item} index={index} />
            ))}
        </div>
        </div>

      </div>
    </div>
  );
};

export default WishlistMain;
