import React, { Fragment, useState } from "react";
import MainPoster from "../MainPoster";
import ProductsSection from "../ProductsSection";
import ProductPoster from "../ProductPoster";


const MenMain = () => {
  const img1 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F2TQfEZ70DeAGCgW2AmrjSA%2F9bbd8c9c65f5a95b089e4102f286be0e%2FProduct_Push_Ecom__Collection_Page_Desktop_Mens.jpg&w=1664&q=85";
  const img2 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F3lQCAFxb7haULVzCw234Bi%2F5726ea7355e0f097da6e5d2c2137c2ee%2FHeadless_Desktop_-_3.jpg&w=3840&q=85";

    const data = [
    {
      id: 1,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/CollegiateShadowWashedTankGSAsphaltGrey-STONEACIDWASHA5A4Y-GCSD_9607392a-4137-4262-9069-ae3e2a258f78_640x.jpg?v=1718012567",
      title: "Arrival 5 Shorts ",
      title2: "Light Support",
      price: 8295,
    },
    {
      id: 2,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_1664x.jpg?v=1716981418",
      title: "MESH TANK ",
      title2: "Regular Fit",
      price: 5295,
    },
    {
      id: 3,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/OversizedPerformanceT-ShirtGSBlackA6A4W-BB2J-0263_640x.jpg?v=1716981420",
      title: "Blur Seamless Cami ",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 4,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSTitaniumBlueA6A2M-UCMQ-0582_7c5f3c19-9d89-4baa-9eb9-3f1748bad9fb_640x.jpg?v=1718102582",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 5,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/ARRIVAL5SHORTSBLACKA2A1M-BBBB_828x.jpg?v=1680785741",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 6,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/products/Sport5ShortBlack-A2A8K-BBBB-2274.241_640x.jpg?v=1677057795",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 7,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F743CBJvd4jWRsRTmXQ3GWt%2F371bdaf5397026a7239ec8ce759aab41%2FMens_Shorts_Shop_By_Length_7_5x4.jpg&w=1664&q=85",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 8,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2FLQs2kZbgrSrr77TCv6cAV%2F68e425eb60861bec72e4f9664047e06d%2FMens_Shorts_Shop_By_Collection_Apex.jpg&w=640&q=90",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
  ];
  const button1 = {
    title: "Shop Lifting",
    path: "",
  };
  const button2 = {
    title: "Find out more",
    path: "",
  };




  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10 pb-20 ">
        <MainPoster
          className={`h-[200px] md:h-[500px]`}
          img={img1}
          title={"NEW ARRIVALS"}
          title2={
            "Brand new drops, brand new reasons to go gym. You’re welcome."
          }
        />
        <div className=" md:py-10">
          <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
            <ProductsSection
              data={data}
              title={"NEW MONTH, NEW DROPS"}
              title2={"MENS"}
            />
          </div>
        </div>

        <ProductPoster
          className={`h-[700px] md:h-[600px]`}
          img={img2}
          title={"ONE PERCENT LIFTING"}
          title2={
            "The looks you’ll love to lift in. Whatever your look, whatever you lift."
          }
          button1={button1}
          button2={button2}
        />
        
      </div>


    
    </Fragment>
  );
};

export default MenMain;
