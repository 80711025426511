import { IoIosArrowDown } from "react-icons/io";
import { Fragment, useState } from "react";

const Select = ({
  label,
  id,
  value,
  options,
  className,
  onChange,
  required,
  error,
  color,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const selectedOption = options.find((option) => option.value === value);
  return (
    <div>
      {/* <div className="relative">
        {label && (
          <label
            htmlFor={id}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white capitalize"
          >
            {label}
          </label>
        )}
        <div
          className={`relative inline-flex border items-center font-medium bg-transparent text-white focus:outline-0 text-sm rounded border-secondary-200 w-full p-3 ${
            isFocused ? "border-white ring-white " : ""
          } ${className}`}
          data-test-id="state-code"
        >
          <span
            className="flex-1 whitespace-nowrap text-left rtl:text-right text-base truncate"
            aria-hidden="true"
            data-test-id={id}
          >
            {selectedOption?.label}
          </span>
        

          <select
            className="absolute w-full inset-0 opacity-0 text-neutral-100 cursor-pointer"
            value={value}
            onChange={onChange}
          >
            {options.map((state) => (
              <option key={state.id} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>

          <IoIosArrowDown />
        </div>
      </div> */}

      <div className="w-full  flex   flex-col gap-2">
        {label && (
          <label
            htmlFor={label}
            className={` text-xs ${color ? color : " text-white"} `}
          >
            {label} {required && <span className="text-secondary-300">*</span>}
          </label>
        )}
        <div className=" flex items-center relative ">
          <select
            {...props}
            id={id}
            value={value}
            onChange={onChange}
            className={`relative inline-flex border items-center font-medium bg-transparent 
            
            ${
              color
                ? " focus:border-secondary-200 focus:border-2"
                : "  focus:border-white  text-white"
            }
            focus:outline-0 text-sm rounded border-secondary-200 w-full p-4   ${
              isFocused ? "border-white ring-white " : ""
            } ${className}`}
          >
            {options.map((item) => (
              <option key={item.id} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className=" absolute right-4">
            <IoIosArrowDown color={color ? "black" : " white"} />
          </div>
        </div>
        {error && (
          <p className=" text-secondary-300 text-xs  font-extralight mt-3 uppercase">
            {error}
          </p>
        )}
      </div>
    </div>
  );
};

export default Select;
