import React from "react";
import { useNavigate } from "react-router-dom";

const Detail = () => {
  const navigation = useNavigate();
  return (
    <div className=" flex flex-col  gap-8 ">
      <div className=" flex gap-5 items-start">
        <div className=" bg-white text-lg  h-14 items-center flex justify-center    w-14 font-semibold rounded-full">
          <h1>Ak</h1>
        </div>
        <div className="  flex flex-col gap-1">
          <h1 className="  tracking-wide   font-semibold text-white uppercase">
            Ashish khanna
          </h1>
          <h1 className=" text-xs  font-light tracking-wide  text-secondary-300">
            aashishkhanna@gmail.com
          </h1>
          <h1 className="  underline text-sm  tracking-wide  font-light  cursor-pointer text-white">
            Log out
          </h1>
        </div>
      </div>

      <div className=" bg-secondary-300 p-5 flex flex-col gap-3">
        <h1 className=" uppercase tracking-wide    text-secondary-100 font-semibold">
          Address Book
        </h1>
        <div>
          <h1 className=" text-sm  text-secondary-200 tracking-wide">
            Ashish Khanna
          </h1>
          <h1 className=" text-sm  text-secondary-200 tracking-wide">Mohali</h1>
          <h1 className=" text-sm  text-secondary-200 tracking-wide">
            140000 Mohali PB
          </h1>
          <h1 className="text-sm  text-secondary-200 tracking-wide">India</h1>
        </div>
        <div>
          <h1 onClick={()=>navigation('/address')} className=" text-secondary-100  cursor-pointer  text-xs underline font-semibold">
            View address book (1)
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Detail;
