
import Layout from "components/Layout/Layout";
import PolicyMain from "components/Policy/PolicyMain";
import React from "react";

const Exchange = () => {
    const data = [
        {
            id: 1,
            title: "Products purchased from One Percent Club Aw India Online Store",
            description: `One Percent Club Aw India is committed to your happiness. We want you to be as pleased with your purchase as we are in serving you. If you're not happy for any reason, we will gladly accept your return within 14 days of your order delivery date. In order to make your return simple and inexpensive, it’s on the house and we’re picking up the tab!`
        },
        {
            id: 2,
            title: "Follow the below steps to Return an Item:",
            description: `        - Visit Initiate Return. \n
            - Enter the order number & mobile number associated with the order. \n
            - Select the product & quantity you wish to return. \n
            - Select the pick-up address. \n
            - Select the pick-up date. (3-5 days window) \n
            - Confirmation SMS shall be sent on your registered mobile number. \n
            - For a guest user, please click here and fill the required details. \n
    `
        },
        {
            id: 3,
            title: " Please Note:",
            description: `           - Any damage caused due to neglect or improper usage will not be covered under our Returns Policy. \n
            - Products can only be returned if they are in an unused condition. \n
            - Face Masks, socks, eyewear, and bodywear products are not applicable for returns. \n
            - All items to be returned must be unused and in their original condition with all original tags and packaging intact (for e.g. shoes must be packed in the original shoe box as well and returned), and should not be broken or tampered with. \n
            - Please note that for all footwear purchases, we kindly request you to refrain from removing the tag loop attached to the product upon receipt. This tag loop serves as an essential part of our return and exchange process. Removal of the tag loop may result in the inability to process returns or exchanges. \n
            - In case of purchase of multiple quantities of the same SKU (size variant), all quantities need to be returned. \n
            - In case of concerns about the delivered product, kindly reach out to our customer support team on 080-35353535 or customercareindia@onepercentclubaw.com within 72 hours of delivery. Please note that we will not be able to address your claims in case this timeline is exceeded. \n
            - If you choose to self-ship your returns yourself instead of using One Percent Club Aw return logistics, kindly call customer support at 080-35353535 MON-SUN : 09:00 - 19:00. \n
    `
        },
        {
            id: 4,
            title: "Customization on onepercentclubaw.com:",
            description: ` You can submit your content up to 6 Letters and 2 digits on selected merchandise which allows personalization (Please note that special characters and spaces may not be supported).
            You may not use pornographic, libelous, defamatory, tortious, obscene, racially, ethnically, or otherwise objectionable content. We (One Percent Club Aw) reserve the right, at our sole discretion, to refuse or cancel any order at any point in time with a complete refund back to the source. The customer agrees not to dispute the decision made by One Percent Club Aw Sports India Pvt Ltd and accepts One Percent Club Aw Sports India Pvt Ltd.’s decision regarding the cancellation.        
         `
        },
        {
            id: 5,
            title: "  Cancellation & return policy:",
            description: `            Once a customized order is successful, products are non-cancellable/returnable. \n
            In case you notice a difference/misspelling in content from your original request, you are requested to report it to our customer support within 48 hours of delivery. \n
            You may call 080-35353535| 37373737 or write to customercareindia@One onepercentclubaw.com (mailto:customercareindia@One onepercentclubaw.com).         \n
         `
        },
        {
            id: 6,
            title: " Products purchased from One Percent Club Aw Retail stores:",
            description: `If the product is purchased from a One Percent Club Aw retail store, please address this matter with the store from which the item was originally purchased. Retail sales associates are extremely \n knowledgeable about our products and will be able to assist you.
         `
        },
        {
            id: 7,
            title: "Exchange:",
            description: `
            We strive to ensure your complete satisfaction with our products. however, if you find the fit of your purchased item unsatisfactory, we offer an exchange option. \n
            - Applicable on Footwear and apparel.  \n
            - Sizes are subject to availability. \n
         `
        },
        {
            id: 8,
            title: "  Initiating an Exchange:",
            description: `
            Eligibility: \n
            - Products must be in unused, unworn, and resalable condition and shall be eligible for exchange. \n
            - The product must be returned in its original packaging with all tags intact. \n
            - Personalized products are not eligible for exchange. \n
         `
        },
        {
            id: 9,
            title: " Limitation:",
            description: `
            - Each customer is entitled to one exchange per order. \n
            - Once we receive the returned item and verify its condition, we will process the exchange order. (Our standard delivery timeline will apply). \n
            - The returned item shall be picked up from the order delivery address only. \n
         `
        },
        {
            id: 10,
            title: " Return Option for exchanged order:",
            description: `
            Following the exchange, customers still have the option to return the product if they are not satisfied. Our standard return policy will apply to such returns. Refunds shall be processed back to the original payment method only. \n
    
            One Percent Club Aw reserves the right to amend or modify this exchange policy at any time without prior notice.
          `
        },
        {
            id: 11,
            title: "Cancellation by One Percent Club Aw Sports India Pvt Ltd:",
            description: `
            Following the exchange, customers still have the option to return the product if they are not satisfied. Our standard return policy will apply to such returns. Refunds shall be processed back to the original payment method only. \n
    
            One Percent Club Aw reserves the right to amend or modify this exchange policy at any time without prior notice.
          `
        },
        {
            id: 12,
            title: "Refund Policy:",
            description: `
            Once the product(s) reaches back to our warehouse and completes the quality check, refund(s) shall be initiated as per the below: \n
            - If your payment method is Prepaid, refunds will be initiated in 48 hours and the amount may take up to 5-15 working days depending upon your bank to reflect in your account. \n
            - For Cash on delivery, we shall send you a link on your registered number & Email Id within 48 hours. You can claim the amount by entering your UPI Id or your Bank Account information. (Link shall be valid up to 7 days).
          `
        },
        {
            id: 13,
            title: "Returned Orders:",
            description: `
            Cancelled Orders: \n
            If your payment method is Prepaid, the refund will reflect in 5-15 working days depending upon your bank.
          `
        },
        {
            id: 14,
            title: "  Warranty Claim:",
            description: `
            If your product has been longer than 30 days and less than three months since the purchase date (available on the invoice) and the item has developed a flaw, then here's what you'll need to do: \n
            
            Products purchased from One Percent Club Aw India Online Store: \n
            Please reach our customer support at 080-35353535 MON-SUN : 09:00 - 19:00.
            
          `
        },
        {
            id: 14,
            title: "             Products purchased from One Percent Club Aw Retail Stores:",
            description: `
            You’ll want to take your item back to the store you bought it from. \n
            General conditions for warranty claim: \n
            Claims are entertained only against manufacturing defects and admissible when shoes have been appropriately used. No claim is admissible on socks & bodywears. \n
            The warranty does not cover damage caused by improper care, misuse, accident or neglect. \n
            The warranty does not cover the laces of the shoe. \n
            The warranty does not cover induced tears, fading due to detergents, etc. \n
            The warranty does not cover products in used condition and has surpassed the warranty period.

            
          `
        },
      
     
      ];
  return (
    <Layout title={"Return Policy"}>
      <PolicyMain title={'Return Policy'} data={data} />
    </Layout>
  );
};

export default Exchange;
