import React from "react";
import { IoSearch } from "react-icons/io5";

const SearchField = ({ placeholder }) => {
  return (
    <div className=" relative flex items-center">
      <input
      maxLength={22}
        style={{ fontWeight: 400 }}
        placeholder={placeholder}
        className=" w-full text-gray p-3   text-secondary-100 text-sm border-secondary-200 border rounded outline-none focus:border-secondary-100 focus:border-2"
        type="text"
      />

      <button className={` right-5  hover:bg-gray-300 p-2 rounded-full absolute`}>
        <IoSearch size={20} className=" text-secondary-100 " />
      </button>
    </div>
  );
};

export default SearchField;
