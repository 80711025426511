import FooterData from "Data/FooterData";
import React from "react";
import { BsShieldLock } from "react-icons/bs";
import { FaTruckFast } from "react-icons/fa6";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";
const Secure = () => {
  const data = [
    {
      id: 1,
      icon: BsShieldLock,
      title: "SECURE PAYMENTS",
      title2: "SSL ENCRYPTION ON ALL TRANSACTIONS",
    },
    {
      id: 2,
      icon: FaTruckFast,
      title: "FREE & FAST RETURNS",
      title2: "FREE RETURN ON ALL QUALIFYING ORDERS",
    },
    {
      id: 3,
      icon: MdOutlineMarkUnreadChatAlt,
      title: "ACTIVE SUPPORT",
      title2: "GET IN TOUCH IF YOU HAVE A PROBLEM",
    },
  ];
  const data2 = FooterData;

  return (
    <div className=" flex flex-col lg:items-center gap-5">
      <div className=" flex flex-col  lg:flex-row lg:justify-center gap-3 ">
        {data.map((item, index) => (
          <div
            key={index}
            className=" border w-full  border-secondary-300  bg-white p-5 lg:p-10 rounded-lg  text-secondary-100 flex flex-col items-center gap-7"
          >
            <item.icon size={35} />
            <div className="  text-center">
              <h1 className=" text-lg font-semibold  tracking-wider">
                {item.title}
              </h1>
              <p className=" text-xs   font-extralight">{item.title2}</p>
            </div>
          </div>
        ))}
      </div>

      <div className=" flex  flex-col items-center gap-2">
        <h1 className=" text-white font-extralight">
          OUR TRUSTED PAYMENT PARTNERS
        </h1>
        <div className=" flex gap-2">
          {data2.payAccept.map((item, index) => (
            <div
              key={index}
              className="  w-10 bg-white rounded overflow-hidden"
            >
              <img className=" w-full h-full  " src={item.img} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Secure;
