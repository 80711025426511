import Layout from "components/Layout/Layout";
import AccessoriesMain from "components/Products/Accessories/AccessoriesMain";
import MenMain from "components/Products/Men/MenMain";
import React from "react";

const Accessories = () => {
  return (
    <Layout title={"Accessories"}>
      <AccessoriesMain />
    </Layout>
  );
};

export default Accessories;


