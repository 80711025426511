import Button from "components/Layout/Fields/Button";
import Button2 from "components/Layout/Fields/Button2";
import Image from "components/Layout/Fields/Image";
import React from "react";

const ProductPoster = ({ title, img, title2, className, button1, button2 }) => {
  return (
    <div className=" ">
      <div className={`w-full  ${className} relative bg-no-repeat bg-center  `}>
        <Image className={""} src={img} />
        <div className=" absolute bottom-0   bg-black/10  w-full h-full px-5  md:px-20 py-10  flex items-end md:items-center">
          <div className=" flex flex-col gap-5 w-full">
            <h1 className="   text-3xl md:text-5xl font-semibold text-white">
              {title}
            </h1>
            <p className="  text-white text-sm">{title2}</p>

            <div className=" flex flex-col md:flex-row gap-3">
              {button1.title && (
                <Button2
                  disabled={false}
                  //  onClick={''}
                  className={"  uppercase "}
                >
                  {button1.title}
                </Button2>
              )}
              {button2.title && (
                <Button
                  disabled={false}
                  //  onClick={''}
                  className={"uppercase "}
                >
                  {button2.title}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPoster;
