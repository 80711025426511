import React from "react";
import "App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "pages/Home/Home";
import { Toaster } from "react-hot-toast";
import Men from "pages/Products/Men";
import Women from "pages/Products/Women";
import Accessories from "pages/Products/Accessories";
import ScrollToTop from "components/Layout/ScrollToTop";
import ProductView from "pages/ProductView/ProductView";
import Cart from "pages/Cart/Cart";
import Checkout from "pages/Checkout/Checkout";
import Account from "pages/Account/Account";
import Address from "pages/Account/Address";
import OrderDetail from "pages/Account/OrderDetail";
import Wishlist from "pages/Wishlist/Wishlist";
import Login from "pages/Login/Login";
import Faq from "pages/Policy/Faq";
import Exchange from "pages/Policy/Exchange";
import Privacy from "pages/Policy/Privacy";
import Terms from "pages/Policy/Terms";


const App = () => {
  return (
    <Router>
      <Toaster />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shop-men" element={<Men />} />
        <Route exact path="/shop-women" element={<Women />} />
        <Route exact path="/shop-women" element={<Women />} />
        <Route exact path="/accessories" element={<Accessories />} />
        <Route exact path="/products" element={<ProductView />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/address" element={<Address />} />
        <Route exact path="/order/detail" element={<OrderDetail />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/return-policy" element={<Exchange />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/terms-and-conditions" element={<Terms />} />





      </Routes>
    </Router>
  );
};

export default App;
