import React, { Fragment } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ title, children }) => {
  return (
    <Fragment>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title> {title} - OnePercentClub AW</title>
      <Navbar />
      {children}
      <Footer/>
    </Fragment>
  );
};

export default Layout;
