import Image from "components/Layout/Fields/Image";
import React, { Fragment } from "react";
import Currency from "react-currency-formatter";
import { FiHeart } from "react-icons/fi";

const ProductsCards = ({ item , openModal}) => {
  return (
    <Fragment>
      <div className="  cursor-pointer flex flex-col gap-2   pb-4 relative  ">
        <div className="  h-[200px] sm:h-[450px] overflow-hidden rounded">
          <Image className={""} src={item.image} alt="" />
        </div>
        <div>
          <div className="  flex px-2   gap-1 flex-col">
            <div className=" flex flex-col gap-1">
              <h1 className=" text-white  font-medium text-sm">{item.title}</h1>
              <h1 className=" text-xs   text-secondary-300  font-light">
                {item.title2}
              </h1>
            </div>
            <h1 className="  text-primary-100 font-medium text-xs">
              <Currency quantity={item.price} currency="inr" />
            </h1>
          </div>
        </div>

        <div className=" absolute  right-2 top-2">
          <button
          onClick={openModal}
            className={`
            flex     rounded-full  overflow-hidden
            p-2    bg-backgound-300 text-secondary-200  `}
          >
            <FiHeart size={16} className="  " />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsCards;
