import Image from "components/Layout/Fields/Image";
import ModalSection from "components/Layout/ModalSection";
import React, { Fragment, useState } from "react";
import Currency from "react-currency-formatter";
import { HiMiniXMark } from "react-icons/hi2";
import { TbRuler2 } from "react-icons/tb";
import Size from "assets/images/sizechart.png";
import Button2 from "components/Layout/Fields/Button2";
import Button3 from "components/Layout/Fields/Button3";
import { FiHeart } from "react-icons/fi";
import { RiLoopRightFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";


const ProductDetail = () => {
  const color = [
    {
      id: 1,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_3840x.jpg?v=1716981418",
    },
    {
      id: 2,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSUnitGreenA6A2M-ECJF-0167_3840x.jpg?v=1716981418",
    },
    {
      id: 3,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSTitaniumBlueA6A2M-UCMQ-0582_7c5f3c19-9d89-4baa-9eb9-3f1748bad9fb_3840x.jpg?v=1718102582",
    },
  ];
  const [select, setSelect] = useState();

  const size = [
    { id: 1, size: "S" },
    { id: 2, size: "M" },
    { id: 3, size: "L" },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openSizeModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeSizeModal = () => {
    setIsModalOpen(false);
  };
const navigation = useNavigate()


  return (
    <Fragment>
      <div className=" flex  flex-col gap-4">
        {/* Price */}
        <div className=" flex flex-col gap-2">
          <div>
            <h1 className=" text-3xl font-semibold text-white tracking-wider">
              MESH TANK
            </h1>
            <h1 className=" text-secondary-300 font-light text-sm">
              Regular Fit
            </h1>
          </div>
          <div>
            <h1 className="  text-primary-100 font-medium  text-base">
              <Currency quantity={8700} currency="inr" />
            </h1>
            <h1 className="  text-secondary-300 font-medium  text-xs line-through">
              <Currency quantity={1200} currency="inr" />
            </h1>
          </div>
          <h1 className=" text-secondary-300  font-light  text-xs">
            Prices include GST
          </h1>
        </div>
        {/* Color */}
        <div className=" flex flex-col  gap-2">
          <div>
            <h1 className=" text-base font-semibold text-white  tracking-wider">
              Color
            </h1>
            <h1 className=" text-secondary-300 font-light text-sm">Black</h1>
          </div>
          <div>
            <div className=" flex flex-row gap-2">
              {color.map((item, index) => (
                <div key={index} className="  w-14 cursor-pointer">
                  <Image src={item.img} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Size */}

        <div className="border-t  py-5  mt-2 border-border-100">
          <div className=" flex flex-col gap-5 ">
            <div className=" flex flex-col gap-2">
              <h1 className=" text-base font-semibold text-white  tracking-wider">
                Size
              </h1>
              <div className="  flex flex-row justify-between">
                {select ? (
                  <h1 className="     text-secondary-300 uppercase text-xs">
                    Selected Size : {select}
                  </h1>
                ) : (
                  <h1 className="    text-secondary-300 uppercase text-xs">
                    Select Size
                  </h1>
                )}

                <h1 className="  text-white text-xs">IN STOCK</h1>
              </div>
            </div>

            <div className=" flex flex-wrap gap-3">
              {size.map((item, index) => (
                <button
                  onClick={() =>
                    setSelect((pre) => (pre === item.size ? null : item.size))
                  }
                  key={index}
                  className={` ${
                    select === item.size
                      ? " bg-white text-secondary-100 border-transparent"
                      : "text-white  border-border-100"
                  } border lg:p-4 p-3 px-5 lg:px-6 cursor-pointer  hover:border-white   `}
                >
                  <h1 className=" text-sm font-light">{item.size}</h1>
                </button>
              ))}
            </div>
            <div
              onClick={openSizeModal}
              className=" flex gap-2  text-white hover:text-secondary-300 items-center cursor-pointer"
            >
              <h1 className=" text-xs underline tracking-wider  ">
                SIZE GUIDE
              </h1>
              <TbRuler2 size={15} />
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className="border-t  py-9  flex flex-col gap-6  border-border-100">
          <div className=" flex flex-col gap-3">
            <Button2
onClick={()=>navigation('/cart')}

              disabled={false}
              className={
                "  whitespace-pre md:px-5  py-4 lg:px-10  text-xs xl:text-base uppercase"
              }
            >
              Add to cart
            </Button2>

            <Button3
            onClick={()=>navigation('/wishlist')}
              disabled={false}
              className={
                " border py-4"
              }
            >
              <FiHeart size={20} />
              Add to Wishlist
            </Button3>
          </div>
          <div className=" flex items-center gap-1">
            <RiLoopRightFill size={18} className="  text-secondary-300" />
            <h1 className=" text-secondary-300 text-sm font-medium">
              Free returns on all qualifying orders.
            </h1>
          </div>
        </div>

        {/* Description */}

        <div className="border-t  py-5  flex flex-col gap-5  border-border-100">
          <div className=" flex flex-col gap-5 ">
            <div className=" flex flex-col gap-2">
              <h1 className=" text-base font-semibold text-white  tracking-wider">
                Description
              </h1>
              <div className="  flex flex-row justify-between">
                <p className=" text-secondary-300 font-light text-sm">
                  We've taken our essential styles and levelled them up with our
                  ESS+ collection. For this tee, we want to remind you that love
                  always wins
                </p>
              </div>
            </div>
          </div>
          <div className=" flex flex-col gap-5 ">
            <div className=" flex flex-col gap-2">
              <h1 className=" text-base font-semibold text-white  tracking-wider">
                Shipping and Returns
              </h1>
              <div className="  flex flex-row justify-between">
                <p className=" text-secondary-300 font-light text-sm">
                  Free return for all qualifying orders within{" "}
                  <span className=" text-white  font-medium">
                    14 days of your order delivery date.
                  </span>{" "}
                  Visit our{" "}
                  <span className=" underline cursor-pointer">
                    Return Policy
                  </span>{" "}
                  for more information. For any queries, please contact Customer
                  Service at 080-35353535 or via{" "}
                  <span className=" underline cursor-pointer">
                    customercareonepercent@gmail.com
                  </span>{" "}
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalSection isOpen={isModalOpen} onClose={closeSizeModal} className="">
        <Fragment>
          <div className="  flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-sm  tracking-wider font-medium">
                SIZE GUIDE
              </h1>
              <button onClick={closeSizeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>
            <div className=" border-y py-5  border-secondary-300 ">
              <h1 className=" text-center font-semibold text-xl text-secondary-100 uppercase tracking-wider">
                International Size Charts Men
              </h1>
            </div>
            <div className=" flex flex-col gap-4">
              <h1 className="tracking-wider text-sm font-semibold text-secondary-100 text-center">
                MEN´S BODY MEASUREMENTS (см)
              </h1>
              <div className=" flex justify-center">
                <div className="   w-[400px]">
                  <Image src={Size} />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default ProductDetail;
