import Layout from "components/Layout/Layout";
import PolicyMain from "components/Policy/PolicyMain";
import React from "react";

const Privacy = () => {
  const data = [
    {
      id: 1,
      title:
        "We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following to learn about our information gathering and dissemination practices.",
      description: ` 
      Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically. \n
      By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website.

      `,
    },
    {
      id: 2,
      title: "2. Use of Demographic / Profile Data / Your Information",
      description: `
      We use personal information to provide the services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to assist sellers in handling and fulfilling orders, enhancing customer experience, resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services, inform you about online and offline offers, products, services, and updates; customize and enhance your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection.  \n

      In our efforts to continually improve our product and service offerings, we collect and analyse demographic and profile data about our users' activity on our Website.  \n
      
      We identify and use your IP address to help diagnose problems with our server, and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information.  \n
      
      We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact information and demographic information (like zip code, age, or income level). We use this data to tailor your experience at our Website, providing you with content that we think you might be interested in and to display content according to your preferences.  \n


      Cookies \n

A "cookie" is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user. We place both permanent and temporary cookies in your computer's hard drive. The cookies do not contain any of your personally identifiable information.


 
       `,
    },
    {
      id: 3,
      title: "3. Sharing of personal information",
      description: `We may share personal information with our other corporate entities and affiliates. These entities and affiliates may market to you as a result of such sharing unless you explicitly opt-out.
      
      
      We may disclose personal information to third parties. This disclosure may be required for us to provide you access to our Services, for fulfillment of your orders, or for enhancing your experience, or to comply with our legal obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent. \n

We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.
      `,
    },
    {
      id: 4,
      title: "4. Security Precautions",
      description: `Our Website has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.
      `,
    },
    {
      id: 5,
      title: "5. Choice/Opt-Out",
      description: `We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us on behalf of our partners, and from us in general, after setting up an account.`,
    },
    {
      id: 6,
      title: "6. Your Consent",
      description: `By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy. \n

      If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.
      
      `,
    },
  ];
  return (
    <Layout title={"Privacy Policy"}>
      <PolicyMain title={"Privacy Policy"} data={data} />
    </Layout>
  );
};

export default Privacy;
