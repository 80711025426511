import Button2 from "components/Layout/Fields/Button2";
import Button4 from "components/Layout/Fields/Button4";
import InputText from "components/Layout/Fields/InputText";
import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RiLoopRightFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Summery = () => {
  const [show, setShow] = useState(false);
  const [coupon, setCoupon] = useState("");

  const openShow = () => {
    setShow(!show);
  };
  const closeShow = () => {
    setShow(false);
  };

  const Icon = show ? IoIosArrowUp : IoIosArrowDown;
  const naviagtion = useNavigate();

  return (
    <div className=" flex flex-col gap-5">
      <div className=" flex items-center justify-center gap-2 border  border-secondary-300 p-3 rounded">
        <RiLoopRightFill size={18} className="  text-secondary-300" />
        <h1 className=" text-secondary-300 text-xs  md:text-sm font-medium uppercase tracking-wider">
          Free returns on all qualifying orders.
        </h1>
      </div>
      <div className=" flex flex-col  gap-4">
        <div
          onClick={openShow}
          className=" bg-secondary-300 p-4 flex justify-between items-center cursor-pointer"
        >
          <h1 className=" uppercase text-secondary-100  text-base">
            Apply a coupon code
          </h1>
          <Icon size={22} />
        </div>
        {show && (
          <div className=" flex gap-4 items-center">
            <InputText
              required={true}
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
              type={"text"}
              className=""
              placeholder={"Enter a coupon code"}
              // error={errors.name}
            />
            <Button4 className={"  uppercase"}>Apply</Button4>
          </div>
        )}
        <div className=" border-y py-4 pb-7 my-3 border-secondary-200 flex flex-col gap-2">
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SUBTOTAL
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              ₹ 8,700.00
            </h1>
          </div>
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              COUPON DISCOUNT 10%{" "}
              <span className="  text-primary-100">(COU10)</span>
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              - ₹ 100.00
            </h1>
          </div>
          <div className=" flex justify-between items-center">
            <h1 className="   text-xs font-medium text-secondary-300 tracking-wider">
              SHIPPING COSTS
            </h1>
            <h1 className=" text-xs font-medium   text-white tracking-wider">
              ₹ 100.00
            </h1>
          </div>
        </div>
        <div className=" flex justify-between items-center">
          <h1 className="   text-base font-medium text-secondary-300 tracking-wider">
            GRAND TOTAL{" "}
            <span className=" text-xs  text-primary-100">
              {" "}
              PRICES INCLUDE GST
            </span>
          </h1>
          <h1 className=" text-base font-medium   text-white tracking-wider">
            ₹ 100.00
          </h1>
        </div>

        <Button4
          onClick={() => naviagtion("/checkout")}
          className={"uppercase text-base"}
        >
          Checkout
        </Button4>
      </div>
      <p className=" text-xs text-secondary-300">
        By continuing, I confirm that I have read and accept the{" "}
        <span className=" underline">Terms and Conditions</span> and the{" "}
        <span className=" underline">Privacy Policy</span>.
      </p>
    </div>
  );
};

export default Summery;
