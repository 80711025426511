import Button from "components/Layout/Fields/Button";
import Button2 from "components/Layout/Fields/Button2";
import React, { Fragment, useRef } from "react";

const VideoSection = () => {
  const videoRef = useRef(null);
  const videoSrc =
    "https://cdn.sanity.io/files/qa41whrn/prod/533018ce06bf7d1b09dd1892c4b491bed2b8cbe7.mp4";
  return (
    <Fragment>
      <div className=" relative  ">
        <video
          className=" w-full h-[650px]   object-cover "
          ref={videoRef}
          autoPlay
          muted
          playsInline
          loop
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <div className=" absolute bottom-0  w-full h-full px-5  md:px-20 py-10  flex items-end md:items-center">
          <div className=" flex flex-col gap-5 w-full">
            <h1 className="   text-2xl md:text-5xl font-semibold text-white">
              NEW MONTH, NEW <br className=" hidden md:flex" /> DROPS
            </h1>
            <p className="  text-white text-sm">
              And even more new reasons to go gym. See you there.
            </p>
            <div className=" flex flex-col md:flex-row gap-3">
              <Button2
                disabled={false}
                //  onClick={''}
                className={"  "}
              >
                Shop Women
              </Button2>
              <Button
                disabled={false}
                //  onClick={''}
                className={" "}
              >
                Shop Men
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VideoSection;
