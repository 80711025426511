import React from "react";
const Button3 = ({ disabled, className, children, ...props }) => {
  return (
    <div>
      <button
        disabled={disabled}
        {...props}
        className={` ${className}    
        whitespace-pre md:px-5  py-3 lg:px-10  text-sm uppercase  text-white  items-center gap-2 border-white hover:border-border-100
        font-normal tracking-wider px-10   w-full      flex justify-center   rounded-full`}
      >
        {children}
    
      </button>
    </div>
  );
};

export default Button3;
