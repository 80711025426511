import Layout from "components/Layout/Layout";
import WishlistMain from "components/Wishlist/WishlistMain";
import React from "react";

const Wishlist = () => {
  return (
    <Layout title={"Wishlist"}>
      <WishlistMain />
    </Layout>
  );
};

export default Wishlist;
