import React, { Fragment, useState } from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import Detail from "./Detail";
import { useNavigate } from "react-router-dom";
import Address from "./Address";
import ModalSection from "components/Layout/ModalSection";
import { HiMiniXMark } from "react-icons/hi2";
import Select from "components/Layout/Fields/Select";
import InputText from "components/Layout/Fields/InputText";
import States from "Data/States";
import Button3 from "components/Layout/Fields/Button3";
import Button5 from "components/Layout/Fields/Button5";
import Button2 from "components/Layout/Fields/Button2";

const AddressMain = () => {
  const navigation = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [selectedState, setSelectedState] = useState("AP");

  const handleChange = (event) => {
    setSelectedState(event.target.value);
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  py-10  ">
        <div className=" container   px-5 xl:px-16 flex flex-col gap-14 pb-20 ">
          <div className=" flex   flex-col-reverse md:flex-row gap-3 md:gap-0  md:justify-center md:items-center   relative">
            <div
              onClick={() => navigation("/account")}
              className=" flex  left-0 md:absolute items-center cursor-pointer gap-2"
            >
              <MdOutlineArrowBackIosNew color="white" />
              <h1 className=" text-sm underline text-white">Back to Account</h1>
            </div>

            <h1 className=" md:text-center font-semibold  text-lg md:text-2xl text-white tracking-wide">
              ADDRESS BOOK
            </h1>
          </div>
          <div className=" flex   gap-10 xl:gap-0 flex-col lg:flex-row justify-between">
            <div className="  lg:sticky  h-fit top-20 lg:w-[25%]">
              <Detail openModal={openModal} />
            </div>
            <div className=" lg:w-[70%]">
              <Address />
              
            </div>
          </div>
        </div>
      </div>
      <ModalSection
        isOpen={isModalOpen}
        onClose={closeModal}
        className="  lg:w-[800px]"
      >
        <Fragment>
          <div className=" px-0 pt-3 pb-10 md:p-3 flex flex-col gap-4 ">
            <div className=" flex justify-between items-center">
              <h1 className=" text-secondary-100 text-lg  tracking-wider font-medium">
                Add Address
              </h1>
              <button onClick={closeModal}>
                <HiMiniXMark size={25} />
              </button>
            </div>

            <form action="" className="  flex flex-col gap-2">
              <div className=" flex flex-col gap-5">
                <div className=" flex flex-col gap-5">
                  <h1 className=" text-lg text-secondary-100 tracking-wider   font-normal uppercase">
                    Enter Contact Info
                  </h1>

                  <div className=" md:col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                    <div className="   ">
                      <InputText
                        label={"EMAIL "}
                        required={true}
                        //   value={coupon}
                        //   onChange={(e) => setCoupon(e.target.value)}
                        type={"text"}
                        className=""
                        placeholder={"Email"}
                        color={"  text-secondary-100"}
                        // error={errors.name}
                      />
                    </div>
                    <div className="   ">
                      <InputText
                        label={"PHONE NUMBER"}
                        required={true}
                        //   value={coupon}
                        //   onChange={(e) => setCoupon(e.target.value)}
                        type={"number"}
                        className=""
                        placeholder={"Phone Number"}
                        color={"  text-secondary-100"}

                        // error={errors.name}
                      />
                    </div>
                  </div>
                </div>

                <div className=" col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                  <div className=" ">
                    <InputText
                      label={"FIRST NAME "}
                      required={true}
                      //   value={coupon}
                      //   onChange={(e) => setCoupon(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"First Name"}
                      // error={errors.name}
                      color={"  text-secondary-100"}
                    />
                  </div>
                  <div>
                    <InputText
                      label={"LAST NAME "}
                      required={true}
                      //   value={coupon}
                      //   onChange={(e) => setCoupon(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"Last Name"}
                      color={"  text-secondary-100"}
                      // error={errors.name}
                    />
                  </div>
                  <div className="   col-span-2">
                    <InputText
                      label={"PIN CODE "}
                      required={true}
                      //   value={coupon}
                      //   onChange={(e) => setCoupon(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"PIN code"}
                      color={"  text-secondary-100"}
                      // error={errors.name}
                    />
                  </div>
                  <div className="   col-span-2">
                    <InputText
                      label={"STREET "}
                      required={true}
                      //   value={coupon}
                      //   onChange={(e) => setCoupon(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"Street"}
                      color={"  text-secondary-100"}
                      // error={errors.name}
                    />
                  </div>
                  <div className="   col-span-2">
                    <InputText
                      label={"ADDRESS 2 "}
                      required={false}
                      //   value={coupon}
                      //   onChange={(e) => setCoupon(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"Address 2"}
                      color={"  text-secondary-100"}
                      // error={errors.name}
                    />
                  </div>
                  <div className=" col-span-2   flex flex-col  md:grid grid-cols-2 gap-5">
                    <div className="   ">
                      <InputText
                        color={"  text-secondary-100"}
                        label={"CITY "}
                        required={true}
                        //   value={coupon}
                        //   onChange={(e) => setCoupon(e.target.value)}
                        type={"text"}
                        className=""
                        placeholder={"City"}

                        // error={errors.name}
                      />
                    </div>
                    <div className=" w-full   ">
                      <Select
                        color={"  text-secondary-100"}
                        required={true}
                        label={"STATE"}
                        id="qty"
                        onChange={handleChange}
                        value={selectedState}
                        options={States}
                        className=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" pt-5">
                <div className=" grid   md:grid-cols-2  gap-3 md:gap-5">
                  <Button5
                    disabled={false}
                    className={
                      "whitespace-pre md:px-5  py-4 lg:px-10  text-sm  bg-secondary-100   hover:bg-secondary-300 uppercase     text-white flex items-center gap-2  hover:border-border-100"
                    }
                  >
                    Save
                  </Button5>

             
                  <Button2
                 onClick={closeModal}
                  disabled={false}
                  className={
                    "whitespace-pre md:px-5  py-2lg:px-10  text-sm uppercase border  text-secondary-100 flex items-center gap-2 hover:border-secondary-200  border-secondary-100 hover:border-border-100"
                  }
                >
                    Cancel
                  </Button2>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      </ModalSection>
    </Fragment>
  );
};

export default AddressMain;
