import Button2 from "components/Layout/Fields/Button2";
import Image from "components/Layout/Fields/Image";
import React, { Fragment } from "react";

const Card = ({ item, index }) => {
  return (
    <div key={index} className=" ">
      <div className="  relative overflow-hidden rounded  ">
        <div className=" h-[600px]  md:h-[350px]  xl:h-[600px]">
          <Image className={""} src={item.image} alt="" />
        </div>

        <div className="  z-10 bottom-0 p-5 absolute">
          <Button2
            disabled={false}
            //  onClick={''}
            className={"  whitespace-pre md:px-5  lg:px-10  text-xs xl:text-sm"}
          >
            {item.title}
          </Button2>
        </div>
      </div>
    </div>
  );
};

const Section2 = () => {
  const data = [
    {
      id: 1,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F2Z7NbzN4e1A2aAyfuXqqHl%2Fb2f57c9f1ca786c3153fc1abb1ef76e2%2FMay_Key_Looks_Web_Banners_Headless_Cards_Womens.jpg&w=640&q=90",
      title: "SHOP WOMEN",
    },
    {
      id: 2,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F6hkUcqn0PcUEzFp2fv9Z0O%2Fd6f4ef5167f57a41cf978f06ce660f77%2FMay_Key_Looks_Web_Banners_Headless_Cards_Mens.jpg&w=640&q=90",
      title: "SHOP MEN",
    },
    {
      id: 3,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F4lZhm59iajAFrvA33IS3pj%2Fbfc3bd68027420cc2bc1770eb617749c%2FMay_Key_Looks_Web_Banners_Headless_Cards_Accessories.jpg&w=640&q=90",
      title: "SHOP ACCESSORIES",
    },
  ];

  return (
    <section>
      <div className="   flex  flex-col gap-5 md:gap-10 w-full ">
        <h1 className=" text-white font-semibold text-xl  md:text-2xl uppercase">
          Ready To Join The Club ? / Join The Club!
        </h1>

        <div className=" ">
          <div className=" grid md:grid-cols-3 gap-3">
            {data.map((item, index) => (
              <Card item={item} key={index} index={index} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
