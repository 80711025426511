import Layout from "components/Layout/Layout";
import MenMain from "components/Products/Men/MenMain";
import React from "react";

const Men = () => {
  return (
    <Layout title={"Men"}>
      <MenMain />
    </Layout>
  );
};

export default Men;
