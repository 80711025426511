import React from "react";

const Image = ({ src, className, alt , ...props }) => {
  return (

      <img
        className={` ${className} w-full h-full object-cover `}
        src={src}
        alt={alt}
        {...props}
      />

  );
};

export default Image;
