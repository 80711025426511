import React, { Fragment } from "react";
import MainPoster from "../MainPoster";
import ProductsSection from "../ProductsSection";
import ProductPoster from "../ProductPoster";

const WomenMain = () => {
  const img1 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F6vIH1BqTnb7pyiEKhQfEK6%2Fd58b416049661af41b38adb2f5d84bd4%2FMay_Key_Looks_Web_Banners_Desktop_Womens_1.jpg&w=3840&q=85";
  const img2 =
    "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F4dAD8FshkRrFlzUjpRpgv3%2F22e9ab7920eb8711fe5781fa9191b1f9%2Fcollection-banner-womens-pink.png&w=3840&q=85";
  const data = [
    {
        id: 1,
        image:
          "https://cdn.shopify.com/s/files/1/1367/5207/files/BlurSeamlessLowBraletteGSIrisBlue_GSPowderedLilacB9A2F-UCVV_828x.jpg?v=1717571493",
        title: "Arrival 5 Shorts ",
        title2: "Light Support",
        price: 8295,
      },
    {
      id: 2,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/BlurSeamlessShortGSIrisBlue_GSPowderedLilacB9A2D-UCVV_828x.jpg?v=1717571493",
      title: "MESH TANK ",
      title2: "Regular Fit",
      price: 5295,
    },
    {
      id: 3,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/GFXTEAMGYMSHARKBRALETTEGSCarmineRedB1B6T-RBQW-0828_828x.jpg?v=1716981410",
      title: "Blur Seamless Cami ",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 4,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F769q95ezpLIp342VjwoS88%2F6505d3ca619bee26943f1e0f63c3f2f4%2FRUNNING_LEGGINGS.jpeg&w=1200&q=90",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 5,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/EverydaySeamlessSportsBraGSBlackB8A4T-BB2J0631_c84f91f6-3935-45ab-87cd-7d3ed439ea66_828x.jpg?v=1710775067",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 6,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/RuchedSportsBraWhiteB2A7A-WBBM-0016_828x.jpg?v=1714127923",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 7,
      image:
        "https://cdn.shopify.com/s/files/1/1367/5207/files/LiftingEssentialsGraphicOversizedSweatshirt-GSBlack-B8A3Z-BB2J-0964_c75b222f-5e38-4228-a07d-ff1c0844b6a2_828x.jpg?v=1715358524",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
    {
      id: 8,
      image:
        "https://row.gymshark.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Fwl6q2in9o7k3%2F4sEuvSsKDcT5QvENhCE3CP%2F1549ab1ef03becaafe51adc0b34ce3c0%2FLifting_leggings.jpeg&w=1200&q=90",
      title: "Blur Seamless Shorts",
      title2: "Black/Asphalt Grey",
      price: 7295,
    },
  ];
  const button1 = {
    title: "",
    path: "",
  };
  const button2 = {
    title: "",
    path: "",
  };

  return (
    <Fragment>
      <div className="bg-secondary-100  flex  flex-col gap-10 pb-20 ">
        <MainPoster
          className={`h-[200px] md:h-[500px]`}
          img={img1}
          title={"NEW MONTH, NEW DROPS"}
          title2={
            "And even more new reasons to go gym. See you there."
          }

        />
        <div className=" md:py-10">
          <div className=" container   px-5 lg:px-16 flex flex-col gap-10 ">
            <ProductsSection
              data={data}
              title={"NEW MONTH, NEW DROPS"}
              title2={"MENS"}
            />
          </div>
        </div>

        <ProductPoster
          className={`h-[300px] md:h-[600px]`}
          img={img2}
          title={"NOTHING BUT THE BEST"}
          title2={
            "They’re called bestsellers for a reason."
          }
          button1={button1}
          button2={button2}
  
        />
      </div>
    </Fragment>
  );
};

export default WomenMain;
