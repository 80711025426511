import Image from "components/Layout/Fields/Image";
import Select from "components/Layout/Fields/Select";
import React, { useState } from "react";
import Currency from "react-currency-formatter";
import { RiDeleteBin6Line } from "react-icons/ri";
const ProductsCard = ({ item }) => {
  const [selected, setSelected] = useState(1);

  console.log(selected, "selected");

  return (
    <div className="  border border-secondary-200 p-5 flex  md:gap-0 gap-5 flex-col md:flex-row  justify-between">
      <div className="   flex   gap-3 md:justify-start  md:gap-5">
        <div className=" w-[90px]    h-24">
          <Image src={item.img} />
        </div>
        <div className="  md:w-auto  w-[calc(100%-130px)]  flex flex-col gap-1 ">
          <div>
            <h1 className="  text-white  tracking-wider  text-base ">
              {item.name}
            </h1>
            <h1 className=" text-xs text-secondary-300  ">Regular Fit</h1>
          </div>

          <div>
            <h1 className="  text-white   tracking-wider    font-extralight  text-xs">
              Color :{" "}
              <span className=" text-secondary-300 font-light capitalize">{item.color}</span>
            </h1>
            <h1 className="  text-white   tracking-wider    font-extralight  text-xs">
              Size : <span className=" text-secondary-300 font-light">L</span>
            </h1>
          </div>
    
        </div>
      </div>

      <div className=" flex justify-between   md:justify-start md:flex-col gap-3">
        <div className=" ">
          <h1 className="  text-primary-100 font-medium  text-sm">
            <Currency quantity={8700} currency="inr" />
          </h1>
          <h1 className="  text-secondary-300 font-medium  text-xs line-through">
            <Currency quantity={1200} currency="inr" />
          </h1>
        </div>
        <div className=" flex justify-end">
          <button className={`  hover:bg-gray-800 p-2 rounded-full  `}>
            <RiDeleteBin6Line size={20} className=" text-white " />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;
