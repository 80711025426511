import CheckoutMain from "components/Checkout/CheckoutMain";
import Layout from "components/Layout/Layout";
import React from "react";

const Checkout = () => {
  return (
    <Layout title={"Checkout"}>
      <CheckoutMain />
    </Layout>
  );
};

export default Checkout;
