import LoginMain from "components/Login/LoginMain";
import React, { Fragment } from "react";

const Login = () => {
  return (
    <Fragment>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title> Account - OnePercentClub AW</title>
      <LoginMain />
    </Fragment>
  );
};

export default Login;
