import Layout from "components/Layout/Layout";
import WomenMain from "components/Products/Women/WomenMain";
import React from "react";

const Women = () => {
  return (
    <Layout title={"Women"}>
      <WomenMain />
    </Layout>
  );
};

export default Women;
