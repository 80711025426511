import States from "Data/States";
import Button4 from "components/Layout/Fields/Button4";
import InputText from "components/Layout/Fields/InputText";
import Select from "components/Layout/Fields/Select";
import React, { useState } from "react";

const Address = ({ displayRazorpay }) => {
  const [select, setSelect] = useState(1);

  const options = [
    { id: 1, title: "19034 Verna Unions Apt. 164 - Honolulu, RI / 87535" },
    { id: 2, title: "Enter New Address" },
  ];
  const selectOption = (item) => {
    setSelect(item);
  };

  const [state, setState] = useState();

  const [selectedState, setSelectedState] = useState("AP");

  const handleChange = (event) => {
    setSelectedState(event.target.value);
  };

  return (
    <div className=" flex flex-col gap-8">
      <div className=" bg-secondary-300 p-4 flex justify-between items-center cursor-pointer">
        <h1 className=" uppercase text-secondary-100  text-base">ADDRESSES</h1>
      </div>

      <div className=" flex flex-col gap-4">
        <div>
          <h1 className=" text-lg text-white tracking-wider  font-extralight uppercase">
            Enter A Shipping Address
          </h1>
        </div>

        <div className="flex flex-col gap-4 ">
          {options.map((item, index) => (
            <div
              onClick={() => selectOption(item.id)}
              key={index}
              className=" border  border-secondary-200 w-full  flex  gap-2 items-center p-4 rounded cursor-pointer"
            >
              <div
                className={` ${
                  select === item.id
                    ? " border-white p-1"
                    : "border-secondary-200  p-[3px]  border-2"
                } 
              
              border  rounded-full cursor-pointer `}
              >
                <div
                  className={` ${
                    select === item.id ? " bg-white" : "   bg-transparent"
                  } p-1.5 rounded-full 1`}
                ></div>
              </div>
              <div>
                <h1 className="  text-base   text-white font-extralight ">
                  {item.title}
                </h1>
              </div>
            </div>
          ))}
        </div>
        <div className=" pt-5 flex flex-col gap-10">
          {select === 2 && (
            <form action="" className=" ">
              <div className=" col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                <div className=" ">
                  <InputText
                    label={"FIRST NAME "}
                    required={true}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"First Name"}
                    // error={errors.name}
                  />
                </div>
                <div>
                  <InputText
                    label={"LAST NAME "}
                    required={true}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"Last Name"}

                    // error={errors.name}
                  />
                </div>
                <div className="   col-span-2">
                  <InputText
                    label={"PIN CODE "}
                    required={true}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"PIN code"}

                    // error={errors.name}
                  />
                </div>
                <div className="   col-span-2">
                  <InputText
                    label={"STREET "}
                    required={true}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"Street"}

                    // error={errors.name}
                  />
                </div>
                <div className="   col-span-2">
                  <InputText
                    label={"ADDRESS 2 "}
                    required={false}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"Address 2"}

                    // error={errors.name}
                  />
                </div>
                <div className=" col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                  <div className="   ">
                    <InputText
                      label={"CITY "}
                      required={true}
                      //   value={coupon}
                      //   onChange={(e) => setCoupon(e.target.value)}
                      type={"text"}
                      className=""
                      placeholder={"City"}

                      // error={errors.name}
                    />
                  </div>
                  <div className=" w-full   ">
                    <Select
                      required={true}
                      label={"STATE"}
                      id="qty"
                      onChange={handleChange}
                      value={selectedState}
                      options={States}
                      className=""
                    />
                  </div>
                </div>
              </div>
            </form>
          )}

          <div className="flex flex-col gap-10">
            <div className=" flex flex-col gap-5">
              <h1 className=" text-lg text-white tracking-wider  font-extralight uppercase">
                Enter Contact Info
              </h1>

              <div className=" md:col-span-2  flex flex-col  md:grid grid-cols-2 gap-5">
                <div className="   ">
                  <InputText
                    label={"EMAIL "}
                    required={true}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"text"}
                    className=""
                    placeholder={"Email"}

                    // error={errors.name}
                  />
                </div>
                <div className="   ">
                  <InputText
                    label={"PHONE NUMBER"}
                    required={true}
                    //   value={coupon}
                    //   onChange={(e) => setCoupon(e.target.value)}
                    type={"number"}
                    className=""
                    placeholder={"Phone Number"}

                    // error={errors.name}
                  />
                </div>
              </div>
            </div>
            <div className=" ">
              <div className=" flex flex-col gap-5">
                <Button4
                  onClick={displayRazorpay}
                  className={"uppercase text-base"}
                >
                  Continue to payment method
                </Button4>
                <p className=" text-xs text-secondary-300">
                  By continuing, I confirm that I have read and accept the{" "}
                  <span className=" underline">Terms and Conditions</span> and
                  the <span className=" underline">Privacy Policy</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
