import ZoomImage from "components/Layout/Fields/ZoomImage";
import React, { Fragment } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CustomDot = ({ onClick, active, img }) => (
  <>
    {img?.length > 1 && (
      <button
        className={` rounded-lg  ${active ?" bg-white " :" bg-secondary-200" }`}
        style={{
         

          width: active ? "20px" : "10px",
          height: "5px",
          margin: "0 5px",
          cursor: "pointer",
        }}
        onClick={() => onClick()}
      />
    )}
  </>
);

const ImagesSection = () => {
  const Images = [
    {
      id: 1,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_3840x.jpg?v=1716981418",
    },
    {
      id: 2,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8294_3840x.jpg?v=1716981418",
    },
    {
      id: 3,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8291_1980251c-c681-41a7-a35c-accca958c212_3840x.jpg?v=1716981418",
    },
    {
      id: 4,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8299_1f57455e-bb05-4fa7-a8b3-5b1cdd54c604_3840x.jpg?v=1716981419",
    },
    {
      id: 5,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8296_0f68d498-73da-4862-bdeb-ed6dd85e9bc5_3840x.jpg?v=1716981419",
    },
    {
      id: 6,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8301_3840x.jpg?v=1716981419",
    },
  ];
  return (
    <Fragment>
      {/* Web */}
      <div className=" hidden md:grid lg:grid-cols-2 gap-5">
        {Images.map((item, index) => (
          <div key={index} className=" overflow-hidden  h-[450px]  ">
            {/* <Image className={""} src={item.img} alt="" /> */}

            <ZoomImage src={item.img} />
          </div>
        ))}
      </div>
      {/* Mobile */}
      <div>
        <div className=" md:hidden  flex  w-full rounded  overflow-hidden ">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            // autoPlay={hasMultipleOfferSliders}
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            containerClass="container"
            dotListClass=""
            draggable
            customDot={<CustomDot img={Images} />}
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover={false}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 1,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={true}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {Images.map((item, index) => (
              <>
                <div key={index} className={`hero-slide relative `}>
                  <div className=" absolute  flex justify-center items-center h-full   w-full  "></div>
                  <div className=" h-[500px] w-full">

                  <img
                    className="   w-full  h-full   object-cover"
                    src={item.img}
                    alt="Default Image"
                  />
                  </div>
                </div>
              </>
            ))}
          </Carousel>
        </div>
      </div>
    </Fragment>
  );
};

export default ImagesSection;
