import AccountMain from "components/Account/AccountMain";
import Layout from "components/Layout/Layout";
import React from "react";

const Account = () => {
  return (
    <Layout title={"Account"}>
      <AccountMain />
    </Layout>
  );
};

export default Account;
