import React from "react";
import ProductsCard from "./ProductsCard";
import Summery from "./Summery";
import ProductSwiper from "components/Cards/ProductSwiper";
import Secure from "./Secure";

const CartMain = () => {
  const data2 = [
    {
      id: 1,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MeshTankGSBlackA6A2M-BB2J8287_1664x.jpg?v=1716981418",
      name: "Mesh Tank",
      color: "black",
      size: "Size",

    },
    {
      id: 1,
      img: "https://cdn.shopify.com/s/files/1/1367/5207/files/MinimalSportsBraWhiteB3A2J-WBBM-0663_3840x.jpg?v=1714127922",
      name:'MINIMAL SPORTS BRA',
      color: "White",
      size: "",
    },
  
  ];

  return (
    <div className="bg-secondary-100  flex  flex-col gap-10  ">
      <div className=" py-5 md:py-10 pb-20  ">
        <div className=" container   px-5  2xl:px-24  ">
          <div className=" flex flex-col gap-10">
            <h1 className=" text-white text-xl md:text-2xl  font-semibold tracking-wider">
              MY SHOPPING CART <span className=" text-secondary-300">(1)</span>
            </h1>
            <div className="   flex flex-col xl:gap-0  gap-10 lg:flex-row  justify-around">
              <div className=" lg:w-[50%] flex  flex-col gap-2 ">
                {data2.map((item,index)=>(

                <ProductsCard  item={item} key={index} />
                ))}
              </div>
              <div className="   lg:w-[45%] ">
                <Summery />
              </div>
            </div>
            <div  className=" py-10 md:py-40">
              <Secure />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartMain;
