import React, { Fragment, useEffect, useRef, useState } from "react";
import Logo from "assets/images/logo.png";
import { FiHeart, FiMenu } from "react-icons/fi";
import { BsBag } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { IoSearch } from "react-icons/io5";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SearchField from "./Fields/SearchField";
import { HiMiniXMark } from "react-icons/hi2";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Button from "./Fields/Button";
import Button2 from "./Fields/Button2";
const Search = ({ searchSection, menuRef, openSearch }) => {
  return (
    <div>
      <div
        className={`${
          searchSection ? "opacity-100" : "opacity-0 hidden"
        }  bg-backgound-100 h-[100vh] w-full transition-opacity ease-in-out fixed z-50`}
      >
        <div ref={menuRef} className="  fadeNav ">
          <div className=" bg-white    min-h-[100vh]  md:min-h-[200px]">
            <div className=" flex flex-col gap-4 p-5 md:p-7 ">
              <div className="   flex justify-between  gap-5  md:gap-10 items-center">
                <div className=" w-full">
                  <SearchField placeholder={"SEARCH ANYTHING"} />
                </div>
                <button
                  onClick={openSearch}
                  className={`items-center flex hover:bg-gray-300 p-1 rounded-full `}
                >
                  <HiMiniXMark size={28} className=" text-secondary-100 " />
                </button>
              </div>

              <div className=" flex-col  flex gap-4">
                <h1 className=" uppercase font-medium">Popular Search Terms</h1>

                <ul className=" flex gap-3">
                  <li className="  bg-tertiary-100 p-2 text-sm rounded cursor-pointer">
                    SHORTS{" "}
                  </li>
                  <li className=" bg-tertiary-100 p-2 text-sm rounded cursor-pointer">
                    T SHIRT
                  </li>
                  <li className=" bg-tertiary-100 p-2 text-sm rounded cursor-pointer">
                    MINI BAG
                  </li>
                  <li className=" bg-tertiary-100 p-2 text-sm rounded cursor-pointer">
                    LEGGINGS
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SideMenu = ({ options, location, sideOptions, closeMenu }) => {
  return (
    <div>
      <div
        className={` 
        absolute h-[100vh]  w-full  transition-opacity ease-in-out  bg-white z-50`}
      >
        <div className=" p-5 flex flex-col gap-5 ">
          <ul className=" flex flex-col gap-3 ">
            {options.map((item, index) => (
              <li
                key={index}
                className={`
                ${
                  item.title === "Home" ? "" : " border-t"
                }    w-full     pt-4     border-secondary-300 `}
              >
                <NavLink
                  onClick={closeMenu}
                  to={item.path}
                  className={`

                 
                  ${location.pathname === item.path ? "" : " "}
                   tracking-wide   font-semibold text-base uppercase   text-secondary-100 flex justify-between items-center  `}
                >
                  {item.title}

                  <MdOutlineArrowForwardIos
                    size={20}
                    className=" text-secondary-100"
                  />
                </NavLink>
              </li>
            ))}
          </ul>

          <div>
            <ul className=" flex flex-col gap-3 ">
              {sideOptions.map((item, index) => (
                <li
                  key={index}
                  className={`
                ${
                  item.id === 1 ? "" : " border-t"
                }    w-full     pt-4     border-secondary-300 `}
                >
                  <NavLink
                    onClick={closeMenu}
                    to={item.path}
                    className={`

                 
                  ${location.pathname === item.path ? "" : " "}
                   tracking-wide     text-sm uppercase   text-secondary-100 flex justify-between items-center  `}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className=" flex flex-col gap-3 py-4">
            <Button>LOGIN</Button>
            <Button2 className={"  border  border-border-100"}>
              REGISTER
            </Button2>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const menuRef = useRef(null);
  const [searchSection, setSearchSection] = useState(false);
  const [sideMenu, setSideMenu] = useState(false);
  const location = useLocation();
  const navigation = useNavigate();

  const openSearch = () => {
    setSearchSection(!searchSection);
  };
  const openMenu = () => {
    setSideMenu(!sideMenu);
  };
  const closeSearch = () => {
    setSearchSection(false);
  };
  const closeMenu = () => {
    setSideMenu(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setSearchSection(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    { id: 1, title: "Home", path: "/" },
    { id: 2, title: "Men", path: "/shop-men" },
    { id: 3, title: "Women", path: "/shop-women" },
    { id: 4, title: "Accessories", path: "/accessories" },
    { id: 5, title: "Our Story", path: "/ourstory" },
  ];
  const sideOptions = [
    { id: 1, title: "My Account", path: "/account" },
    { id: 2, title: "Wishlist", path: "/" },
    { id: 2, title: "Orders", path: "/" },
  ];

  const iconsPath = [
    { id: 1, Icon: FiHeart, path: "/wishlist" },
    { id: 2, Icon: BsBag, path: "/cart" },
    // { id: 3, Icon: FiUser, path: "/login" },
  ];
  const promotion = [
    { id: 1, title: "FREE RETURNS AND FREE EXCHANGE." },
    {
      id: 2,
      title:
        "EXTRA 5% DISCOUNT FOR ALL ONLINE PAYMENTS. (NOT APPLICABLE ON FENTY x ONE PERCENT CLUB)",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === promotion?.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [currentIndex?.length]);

  const Icon = sideMenu ? HiMiniXMark : FiMenu;

  const homeNavigate = () => {
    navigation("/");
    closeSearch();
    closeMenu();
  };

  return (
    <Fragment>
      <Search
        searchSection={searchSection}
        menuRef={menuRef}
        openSearch={openSearch}
      />

      <div className=" py-6  px-3   bg-primary-100 relative   ">
        {/* Promotions */}
        <div className="   relative   w-full flex justify-center  items-center">
          {promotion.map((item, index) => (
            <div key={index} className={`absolute`}>
              <h1
                className={`${
                  currentIndex === index ? "opacity-100" : "opacity-0"
                } text-center transition-all delay-75 w-full h-full text-xs md:text-sm  font-medium  text-white`}
              >
                {item.title}
              </h1>
            </div>
          ))}
        </div>
      </div>

      <div className="  z-20  sticky top-0 bg-secondary-100 w-full   ">
        <div className="py-4 md:px-10  px-5">
          <div className=" xl:container flex justify-between lg:grid  grid-cols-8  items-center">
            {/* Logo */}
            <div
              onClick={homeNavigate}
              className="  w-16 h-11 cursor-pointer  col-span-2"
            >
              <img className=" w-full h-full " src={Logo} alt="" />
            </div>

            {/* Options */}
            <div className="  hidden lg:flex justify-center col-span-4">
              <ul className=" flex gap-5 justify-center">
                {options.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={item.path}
                      className={`
                  ${
                    location.pathname === item.path
                      ? " border-primary-100"
                      : "border-transparent "
                  }
                  text-sm  text-white border-b-2 uppercase  pb-1 tracking-wide   hover:border-primary-100  `}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            {/* Icons */}
            <div className=" flex items-center justify-end gap-5 col-span-2">
              <div
                onClick={openSearch}
                className=" hidden xl:flex gap-2 items-center border px-6 py-2 rounded border-border-100 hover:border-white cursor-pointer"
              >
                <IoSearch size={20} className=" text-white" />
                <h1 className=" text-white text-sm uppercase">Search</h1>
              </div>
              <div>
                <ul className=" flex gap-2">
                  <li
                    onClick={openSearch}
                    className=" xl:hidden flex rounded-full  overflow-hidden"
                  >
                    <button className={`  hover:bg-gray-800 p-2`}>
                      <IoSearch size={20} className=" text-white " />
                    </button>
                  </li>

                  {iconsPath.map((item, index) => (
                    <li
                      key={index}
                      className=" flex rounded-full  overflow-hidden"
                    >
                      <NavLink to={item.path} className={`  hover:bg-gray-800 p-2`}>
                        <item.Icon size={20} className=" text-white " />
                      </NavLink>
                    </li>
                  ))}
                  <li className=" hidden  lg:flex rounded-full  overflow-hidden">
                    <NavLink to={'/account'} className={`  hover:bg-gray-800 p-2`}>
                      <FiUser size={20} className=" text-white " />
                    </NavLink>
                  </li>
                  <li className="  lg:hidden rounded-full  overflow-hidden">
                    <button
                      onClick={openMenu}
                      className={`  hover:bg-gray-800 p-2`}
                    >
                      <Icon size={20} className=" text-white " />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {sideMenu && (
          <SideMenu
            closeMenu={closeMenu}
            sideMenu={sideMenu}
            options={options}
            location={location}
            sideOptions={sideOptions}
          />
        )}
      </div>
    </Fragment>
  );
};

export default Navbar;
