import Layout from "components/Layout/Layout";
import DetailMain from "components/OrderDetail/DetailMain";
import React from "react";

const OrderDetail = () => {
  return (
    <Layout title={"Orer Detail"}>
      <DetailMain />
    </Layout>
  );
};

export default OrderDetail;
